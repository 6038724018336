@charset "UTF-8";
body {
  --bg-color: #fff;
  --bg-color-subtle: #f7f7f7;
  --bg-color-dark: #4A4A4A;
  --bg-color-darker: #2a2a2a;
  --text-color: #4A4A4A;
  --text-color-white: #FFFFFF;
  --title-color-subtle: #e6e6e6;
  --text-color-subtle: #b9b9b9;
  --anchor-color: #4A4A4A;
  --dark: #4A4A4A;
  --darker: #2a2a2a;
  --white: #ffffff;
  --dark-shadow: rgba(74, 74, 74, 0.1);
  --anchor-hover-color: #FFAC84;
  --orange-color: #FFAC84;
  --border-color: #4A4A4A;
  --border-color-subtle: rgb(247, 247, 247);
  --error-color: #ff0000;
  --success-color: #6bc6b2; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYXgKVElMYYaJe8bpLHnCwDKhdzeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYXgKVElMYYaJe8bpLHnCwDKhdXeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYXgKVElMYYaJe8bpLHnCwDKhd7eFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYXgKVElMYYaJe8bpLHnCwDKhd_eFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYXgKVElMYYaJe8bpLHnCwDKhdHeFaxOedc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjSL9AIxsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjSL9AIVsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjSL9AI5sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjSL9AI9sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjSL9AIFsdP3pBms.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjWr7AIxsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjWr7AIVsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjWr7AI5sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjWr7AI9sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url(https://fonts.gstatic.com/s/ibmplexsans/v4/zYX9KVElMYYaJe8bpLHnCwDKjWr7AIFsdP3pBms.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin */
@font-face {
  font-family: 'Vidaloka';
  font-style: normal;
  font-weight: 400;
  src: local("Vidaloka "), local("Vidaloka-Regular"), url(https://fonts.gstatic.com/s/vidaloka/v11/7cHrv4c3ipenMKlEavs7wH8Dnzcj.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none !important; }

* {
  position: relative;
  box-sizing: border-box;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px; }

body {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  color: var(--text-color);
  background-color: var(--bg-color); }

img {
  max-width: 100%;
  height: auto; }

svg {
  max-height: 100%; }

a {
  color: var(--anchor-color);
  text-decoration: none;
  cursor: pointer; }
  a:hover {
    color: var(--anchor-hover-color); }

.invisible {
  opacity: 0; }

a.underline,
.text a {
  text-decoration: underline; }

.bold-underline {
  border-bottom: 1px solid var(--dark); }

a.bold-underline:hover {
  border-color: var(--anchor-hover-color); }

.uppercase {
  text-transform: uppercase; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Vidaloka", serif;
  font-weight: 400;
  line-height: 1.25;
  margin-top: 1em;
  margin-bottom: .5em; }

h1 {
  font-size: 2rem; }

h2 {
  font-size: 1.5rem; }

h3 {
  font-size: 1.25rem; }

h4 {
  font-size: 1rem; }

h5 {
  font-size: .875rem; }

h6 {
  font-size: .75rem; }

a:hover {
  color: var(--anchor-hover-color); }

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  text-decoration: underline; }

blockquote,
dl,
ol,
p,
pre,
ul {
  margin-top: 1em;
  margin-bottom: 1em; }

code,
pre,
samp {
  font-family: Roboto Mono, Source Code Pro, Menlo, Consolas, Liberation Mono, monospace; }

code,
samp {
  padding: .125em; }

code,
pre,
samp {
  font-size: 87.5%; }

pre {
  overflow: scroll; }

blockquote {
  font-size: 1.25rem;
  font-style: italic;
  margin-left: 0; }

hr {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  border: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--border-color-subtle); }

button {
  /* Remove excess padding and border in Firefox 4+ */ }
  button.reset {
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none; }
  button::-moz-focus-inner {
    border: 0;
    padding: 0; }

.border-darken {
  border-color: var(--border-color); }

.border-subtle {
  border-color: var(--border-color-subtle); }

.black {
  color: var(--orange-color); }

.white {
  color: var(--orange-color); }

.gray {
  color: var(--orange-color); }

.bg-white {
  background-color: var(--bg-color); }

.bg-subtle {
  background-color: var(--bg-color-subtle); }

.bg-dark {
  background-color: var(--bg-color-dark); }

.orange {
  color: var(--orange-color); }

.border-orange {
  border-color: var(--orange-color); }

.vida-loka {
  font-family: 'Vidaloka', serif; }

.plex-sans {
  font-family: 'IBM Plex Sans', sans-serif; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.label {
  font-size: .875rem;
  font-weight: 700;
  display: block;
  margin-bottom: .5rem; }

.input,
.select {
  height: 2.5rem; }

.input,
.select,
.textarea {
  font-family: inherit;
  font-size: inherit;
  display: block;
  width: 100%;
  padding: .5rem;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color-subtle);
  border-radius: 3px; }

pre {
  padding-left: 1rem;
  border-left: 3px solid var(--border-color); }

.color-inherit {
  color: inherit; }

.border-minimal {
  border-width: 1px; }

.border-thick {
  border-width: 3px; }

[data-aspect-ratio] {
  display: block;
  max-width: 100%;
  position: relative; }
  [data-aspect-ratio]:before {
    content: '';
    display: block; }
  [data-aspect-ratio] > * {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0; }

[data-aspect-ratio="21:9"]:before {
  padding-top: 42.85%; }

[data-aspect-ratio="16:9"]:before {
  padding-top: 56.25%; }

[data-aspect-ratio="3:1"]:before {
  padding-top: 33.33%; }

[data-aspect-ratio="3:2"]:before {
  padding-top: 66.66%; }

[data-aspect-ratio="4:3"]:before {
  padding-top: 75%; }

[data-aspect-ratio="1:1"]:before {
  padding-top: 100%; }

[data-aspect-ratio="portrait"]:before {
  padding-top: 67%; }

form .form-control input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  box-shadow: none;
  width: 100%;
  padding: 0.25rem;
  font-size: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  color: var(--text-color);
  outline: none; }

.negative-mx1 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: calc(100% + 1rem); }

.colored,
.colored > a {
  color: var(--anchor-hover-color); }

#outdated {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  text-align: center;
  text-transform: uppercase;
  z-index: 1500;
  background-color: #f25648;
  color: var(--text-color-white); }

* html #outdated {
  position: absolute; }

#outdated h6 {
  margin: 30px 0 10px;
  font-size: 2.3vw;
  line-height: 1;
  letter-spacing: 0.02rem; }

#outdated p {
  font-size: 16px;
  line-height: 1.2;
  margin: 10px 0; }

#outdated #btnUpdateBrowser {
  display: block;
  position: relative;
  padding: 10px 20px;
  margin: 10px auto;
  font-size: 14px;
  width: 230px;
  color: var(--text-color-white);
  text-decoration: none;
  border: 2px solid var(--text-color-white);
  cursor: pointer; }

#outdated #btnUpdateBrowser:hover {
  color: #f25648;
  background-color: var(--text-color-white); }

#outdated .last {
  position: absolute;
  top: 10px;
  right: 25px;
  width: 20px;
  height: 20px; }

#outdated #btnCloseUpdateBrowser {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--text-color-white);
  font-size: 36px; }

@font-face {
  font-family: "alter-chao";
  src: url("../fonts/alter-chao.eot?tsudea");
  src: url("../fonts/alter-chao.eot?tsudea#iefix") format("embedded-opentype"), url("../fonts/alter-chao.ttf?tsudea") format("truetype"), url("../fonts/alter-chao.woff?tsudea") format("woff"), url("../fonts/alter-chao.svg?tsudea#alter-chao") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "alter-chao" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #4a4a4a;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  [class^="icon-"]:before,
  [class*=" icon-"]:before {
    color: inherit; }

.icon-anchor:before {
  content: ""; }

.icon-arrow-back-long:before {
  content: ""; }

.icon-arrow-forward-long {
  display: inline-block;
  transform: rotate(-180deg); }
  .icon-arrow-forward-long:before {
    content: ""; }

.icon-globe:before {
  content: ""; }

.icon-triangle-down:before {
  content: ""; }

.icon-triangle-up:before {
  content: ""; }

.icon-triangle-right {
  transform: rotate(90deg); }
  .icon-triangle-right:before {
    content: ""; }

.icon-triangle-left {
  transform: rotate(-90deg); }
  .icon-triangle-left:before {
    content: ""; }

.icon-arrow-forward:before {
  content: ""; }

.icon-small-arrow-left:before {
  content: ""; }

.icon-small-arrow-right:before {
  content: ""; }

.icon-language:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-whatsapp:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-twitter.colored {
  color: #1da1f2; }

.icon-facebook:before {
  content: ""; }

.icon-facebook.colored {
  color: #3B5998; }

.icon-play:before {
  content: ""; }

.icon-panorama:before {
  content: ""; }

@keyframes border {
  0% {
    box-shadow: 60px -60px 0 2px rgba(0, 0, 0, 0.3), -60px -60px 0 2px rgba(0, 0, 0, 0.3), -60px 60px 0 2px rgba(0, 0, 0, 0.3), 60px 60px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #E94E3D; }
  25% {
    box-shadow: 0 -125px 0 2px rgba(0, 0, 0, 0.3), -60px -60px 0 2px rgba(0, 0, 0, 0.3), -60px 60px 0 2px rgba(0, 0, 0, 0.3), 60px 60px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff; }
  50% {
    box-shadow: 0 -125px 0 2px rgba(0, 0, 0, 0.3), -125px 0px 0 2px rgba(0, 0, 0, 0.3), -60px 60px 0 2px rgba(0, 0, 0, 0.3), 60px 60px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff; }
  75% {
    box-shadow: 0 -125px 0 2px rgba(0, 0, 0, 0.3), -125px 0px 0 2px rgba(0, 0, 0, 0.3), 0px 125px 0 2px rgba(0, 0, 0, 0.3), 60px 60px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff; }
  100% {
    box-shadow: 0 -125px 0 2px rgba(0, 0, 0, 0.3), -125px 0px 0 2px rgba(0, 0, 0, 0.3), 0px 125px 0 2px rgba(0, 0, 0, 0.3), 120px 40px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.global.mobile {
  overflow-x: hidden; }

table {
  border-collapse: separate;
  border-spacing: 0;
  max-width: 100%;
  width: 100%; }

td,
th {
  padding: .5em 0;
  line-height: inherit; }

th {
  text-align: left;
  font-weight: 700;
  vertical-align: bottom; }

td {
  vertical-align: top; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.delay-1s {
  animation-delay: 1s; }

.btn {
  font-family: inherit;
  font-size: .875rem;
  font-weight: 700;
  text-decoration: none;
  height: 2.5em;
  margin: 0;
  padding: .5em 1em;
  border: 0;
  border-radius: 3px;
  color: var(--text-color);
  background-color: var(--bg-color-subtle); }

.app {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }

main.container > div:not(.home) {
  margin-top: 80px; }

[ui-view].ng-leave {
  animation: fadeOut 0.25s; }

[ui-view].ng-enter {
  animation: fadeIn 0.25s; }

@media screen and (max-width: 72em) {
  .navbar-container {
    max-width: 100vw;
    padding-right: 1rem; } }

.navbar .navbar-language,
.navbar .navbar-search {
  min-width: 50px;
  padding: 0; }
  @media screen and (min-width: 64em) {
    .navbar .navbar-language > label,
    .navbar .navbar-search > label {
      line-height: 26px;
      display: inline-block; } }

.navbar .navbar-main {
  min-width: 920px; }
  .navbar .navbar-main a {
    padding: 0 1rem;
    text-transform: uppercase;
    font-size: 100%;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0;
    text-align: center; }
    .navbar .navbar-main a.active {
      color: var(--anchor-hover-color); }
  .navbar .navbar-main img {
    margin: 0 2rem;
    max-width: 120px; }
  .navbar .navbar-main .dropdown a {
    padding: 0.25rem;
    line-height: 1.6;
    text-transform: none; }
    .navbar .navbar-main .dropdown a:hover {
      opacity: 1; }
  .navbar .navbar-main .dropdown .dropdown {
    left: calc(100% + 1.75rem);
    margin: 0;
    top: 0; }
    .navbar .navbar-main .dropdown .dropdown:before {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: 0;
      top: 0;
      left: auto;
      right: 100%;
      width: 2rem; }
    .navbar .navbar-main .dropdown .dropdown:after {
      display: none; }

@media screen and (max-width: 64em) {
  .navbar > div {
    line-height: 60px;
    vertical-align: middle;
    display: inline-block; } }

.navbar .navbar-search > label {
  z-index: 2; }
  @media screen and (max-width: 64em) {
    .navbar .navbar-search > label {
      z-index: 2;
      vertical-align: middle;
      line-height: 1;
      display: inline-block; }
      .navbar .navbar-search > label .icon-search {
        font-size: 30px; } }

.navbar .navbar-search .icon-search {
  font-size: 21px;
  cursor: pointer; }

.navbar .navbar-search .search-container {
  position: absolute;
  line-height: 0;
  left: calc(50% + 20px);
  top: 0;
  width: 0;
  overflow: hidden;
  transition: width 0.2s ease-in-out; }
  .navbar .navbar-search .search-container.active {
    width: 165px; }
  .navbar .navbar-search .search-container button {
    position: absolute;
    top: calc(50% - 10px);
    right: 0;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.3s; }
  .navbar .navbar-search .search-container input {
    background-color: transparent;
    box-shadow: none;
    line-height: 1;
    padding: 5px 5px 5px 25px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 150px;
    font-size: 100%;
    float: left;
    outline: 0; }
    .navbar .navbar-search .search-container input.ng-valid ~ button#triggerSearch {
      opacity: 1; }
    .navbar .navbar-search .search-container input.ng-invalid ~ button#triggerSearch,
    .navbar .navbar-search .search-container input.ng-empty ~ button#triggerSearch {
      opacity: 0; }

.navbar .navbar-language label#currentLang {
  padding: 0 0.5rem; }

.navbar .hamburguer {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  padding: 0;
  background-color: transparent; }
  .navbar .hamburguer:focus {
    outline-style: dotted;
    outline-width: 0.05em;
    outline-color: rgba(0, 0, 0, 0.3); }
  .navbar .hamburguer:before {
    content: '';
    position: absolute;
    height: 60px;
    width: 60px;
    top: calc(50% - 25px);
    left: calc(50% - 25px); }
  .navbar .hamburguer .hamburguer-inner {
    position: absolute;
    width: 25px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 3px;
    background-color: var(--text-color);
    top: 5px; }
    .navbar .hamburguer .hamburguer-inner:after {
      content: '';
      position: absolute;
      width: 25px;
      height: 3px;
      left: 0;
      border-radius: 3px;
      background-color: var(--text-color);
      top: 10px;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform; }

.main-menu {
  background-color: var(--bg-color);
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  height: 80px; }
  .main-menu.no-bg {
    background-color: transparent; }
    .main-menu.no-bg .navbar .navbar-search .search-container input {
      color: var(--text-color-white);
      font-weight: 500; }
    .main-menu.no-bg ::placeholder {
      color: var(--text-color-white);
      opacity: 1;
      /* Firefox */ }
    .main-menu.no-bg :-ms-input-placeholder {
      color: var(--text-color-white); }
    .main-menu.no-bg ::-ms-input-placeholder {
      color: var(--text-color-white); }
  .main-menu.active-menu {
    background-color: var(--bg-color-darker); }
    .main-menu.active-menu .navbar .hamburguer-inner {
      transition-delay: 75ms;
      transform: translate3d(0, 10px, 0) rotate(45deg); }
      .main-menu.active-menu .navbar .hamburguer-inner:after {
        transition-delay: 75ms;
        transform: translate3d(0, -10px, 0) rotate(-90deg); }
  .main-menu.fixed:not(.no-bg) {
    position: fixed; }
    .main-menu.fixed:not(.no-bg) + main {
      margin-top: 80px; }

.no-bg .navbar-language > .navbar-item > a label,
.no-bg .navbar .navbar-main a,
.no-bg .navbar i {
  color: var(--text-color-white); }

.no-bg .navbar .navbar-main a.active {
  font-weight: 700; }

.no-bg .navbar .navbar-main a:hover {
  color: var(--anchor-hover-color); }

.no-bg .navbar .hamburguer .hamburguer-inner,
.no-bg .navbar .hamburguer .hamburguer-inner:after {
  background-color: var(--bg-color); }

li.navbar-item {
  display: flex; }
  @media screen and (max-width: 64em) {
    li.navbar-item {
      max-width: 300px;
      margin: 1rem auto; } }
  li.navbar-item:hover > .dropdown {
    width: 16em;
    left: 50%;
    margin: 0 0 0 -8em;
    padding-top: 10px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: visibility 0s, opacity 0.3s;
    z-index: 33; }
    li.navbar-item:hover > .dropdown.large {
      width: 18em;
      margin: 0 0 0 -9em; }

.dropdown {
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  text-align: left;
  pointer-events: none;
  transition: visibility 0s, opacity 0s;
  background-color: var(--bg-color);
  top: calc(100% + 3rem);
  z-index: 33;
  padding: 0.5rem 1rem;
  box-shadow: 0 15px 25px 0 var(--dark-shadow); }
  .dropdown:after {
    bottom: 100%;
    content: '';
    position: absolute;
    pointer-events: none;
    width: 0;
    left: calc(50% - 5px);
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--text-color-white);
    z-index: 3; }
  .dropdown:before {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 100%;
    left: 0;
    height: 3rem;
    width: 100%; }
  .dropdown li {
    width: 100%; }
    .dropdown li a {
      width: 100%;
      padding: 0.5rem 0.25rem;
      font-size: 100%; }
      .dropdown li a label {
        color: var(--text-color); }
      .dropdown li a * {
        cursor: pointer; }
      .dropdown li a i {
        font-size: 18px;
        right: -10px; }
      .dropdown li a.border-subtle {
        border-color: var(--border-color-subtle); }
    .dropdown li:last-child a {
      border-bottom: 0; }
  .dropdown .border-subtle {
    border-color: rgba(0, 0, 0, 0.1); }
  .dropdown.languages a.current label {
    font-weight: 500; }
  .dropdown.languages .round-flag {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden; }
    .dropdown.languages .round-flag > img {
      width: 40px;
      height: 40px;
      max-width: none;
      position: absolute;
      left: calc(50% - 20px);
      top: calc(50% - 20px); }
      .dropdown.languages .round-flag > img.pt, .dropdown.languages .round-flag > img.es {
        left: calc(50% - 18px); }

.menu-overlay {
  position: absolute;
  z-index: 98;
  top: 0;
  right: 0;
  left: 0;
  height: calc(100vh - 80px);
  background-color: var(--bg-color-darker);
  margin-top: 80px;
  pointer-events: none;
  opacity: 0;
  overflow-y: auto; }
  .menu-overlay > div {
    overflow-y: auto; }
  .menu-overlay a {
    color: var(--text-color-white); }
  .menu-overlay .background-text {
    font-size: 40vw;
    position: absolute;
    left: 0;
    right: 0;
    top: -10px;
    text-align: center;
    font-family: "Vidaloka", serif; }
  .menu-overlay .languages a.current {
    font-weight: 500;
    border-bottom: 2px solid var(--anchor-hover-color); }

.active-menu .menu-overlay {
  pointer-events: all;
  opacity: 1; }

.full-height-slider {
  min-height: calc(100vh - 80px); }

.slideshow {
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  color: var(--text-color-white);
  box-shadow: 0 10px 25px 0 var(--dark-shadow);
  z-index: 1; }
  @media screen and (max-width: 64em) {
    .slideshow {
      box-shadow: none; } }
  .slideshow .slide {
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    position: absolute;
    overflow-x: hidden;
    z-index: 1;
    opacity: 0; }
  .slideshow .slide--current {
    opacity: 1;
    z-index: 3;
    position: relative; }
  .slideshow .slide-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative; }
  .slideshow .slide-img {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--bg-color);
    background-size: cover;
    background-position: center;
    position: absolute; }
  .slideshow .slide-img:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, rgba(26, 26, 26, 0.59) 0%, rgba(0, 0, 0, 0) 83%); }
  .slideshow .slide-title-wrap {
    padding: 2rem 1rem 0;
    text-align: center;
    position: absolute; }
    @media screen and (min-width: 64em) {
      .slideshow .slide-title-wrap {
        max-width: 36rem;
        right: calc(50% - 4rem); } }
    @media screen and (max-width: 64em) {
      .slideshow .slide-title-wrap {
        padding: 2rem 1rem;
        max-height: calc(100% - 120px); } }
  .slideshow .slide-title {
    margin: 0.5rem 0 0;
    font-size: 2.6vw;
    line-height: 1;
    letter-spacing: 0.02rem; }
    @media screen and (min-width: 64em) {
      .slideshow .slide-title {
        font-size: 2.2vw; } }
    .slideshow .slide-title > a {
      color: inherit; }
      .slideshow .slide-title > a:hover, .slideshow .slide-title > a:focus {
        color: inherit;
        text-decoration: none; }
    .slideshow .slide-title span {
      display: inline-block; }
  .slideshow .slide-subtitle {
    stop-opacity: 100%;
    font-weight: 500;
    letter-spacing: 0.02rem; }
  .slideshow .boxnav {
    position: absolute;
    bottom: auto;
    left: 0;
    display: flex;
    z-index: 1000;
    width: 100%;
    z-index: 999; }
  .slideshow .progress-nav-item {
    display: flex;
    align-items: center;
    background: var(--color-nav-bg);
    border: 0;
    height: 4rem;
    flex: 1; }
    @media screen and (max-width: 64em) {
      .slideshow .progress-nav-item {
        -webkit-box-pack: center;
        justify-content: center; } }
  .slideshow .progress-nav-item--prev:hover,
  .slideshow .progress-nav-item--next:hover {
    color: var(--color-nav-hover); }
  .slideshow .progress-nav-item:focus {
    outline: none; }
  .slideshow .progress-nav-label {
    padding: 0 0.5rem;
    font-size: 1.15rem;
    position: relative;
    font-family: "Vidaloka", serif;
    font-size: 1.4rem; }
  .slideshow .progress-nav-label-dash-container {
    width: 175px;
    height: 3px;
    background-color: var(--text-color-white); }
    .slideshow .progress-nav-label-dash-container .progress-nav-label-dash-current {
      height: 100%;
      background-color: var(--orange-color); }
  .slideshow .slide {
    height: 100%; }
  .slideshow .slide-title-wrap {
    max-width: 32rem;
    text-align: left;
    width: 50%;
    right: 50%;
    left: auto;
    z-index: 999; }
    @media screen and (max-width: 64em) {
      .slideshow .slide-title-wrap {
        width: calc(100% - 2rem);
        right: 1rem;
        left: 1rem;
        padding-bottom: 64px; } }
  .slideshow .slide-title {
    font-size: 2.6vw; }
    @media screen and (max-width: 64em) {
      .slideshow .slide-title {
        font-size: 2.4rem; } }
    @media screen and (max-width: 48em) {
      .slideshow .slide-title {
        font-size: 1.8rem; } }
  .slideshow .slide-subtitle {
    margin: 1rem 0 0 0.2rem; }
    @media screen and (max-width: 64em) {
      .slideshow .slide-subtitle {
        font-size: 13px; } }
  .slideshow .slide-category,
  .slideshow .slide-anchor {
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-weight: 700;
    font-size: 13px; }
    @media screen and (max-width: 64em) {
      .slideshow .slide-category,
      .slideshow .slide-anchor {
        font-size: 12px; } }
  .slideshow .slide-anchor {
    color: var(--text-color-white);
    margin: 1rem 0 0 0.2rem;
    display: inline-block;
    cursor: pointer; }
    .slideshow .slide-anchor:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      height: 2px;
      background-color: var(--text-color-white);
      transform: scale3d(0, 1, 1);
      transform-origin: 0 50%;
      transition: transform 0.1s;
      max-width: 95%; }
    .slideshow .slide-anchor:hover:after {
      transform: scale3d(1, 1, 1);
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 0.3s; }
  .slideshow .boxnav {
    left: auto;
    width: 36rem;
    right: calc(50% - 4rem); }
    @media screen and (max-width: 64em) {
      .slideshow .boxnav {
        left: 0;
        right: 0;
        text-align: center;
        width: 100vw;
        bottom: 46px; } }
    @media screen and (max-height: 520px) {
      .slideshow .boxnav {
        bottom: 0; } }
  @media screen and (max-width: 64em) {
    .slideshow:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 3px;
      height: 36px;
      background-color: var(--bg-color);
      z-index: 999;
      left: calc(50% - 1px); } }
  @media screen and (max-height: 520px) {
    .slideshow:after {
      display: none; } }

.nav-items {
  z-index: 2;
  width: 100%;
  min-height: 130px; }
  .nav-items .nav-item {
    padding: 0.75rem 1rem;
    margin-top: 0.5rem;
    cursor: pointer; }
    .nav-items .nav-item * {
      cursor: pointer; }
    @media screen and (min-width: 108em) {
      .nav-items .nav-item {
        padding: 2rem 3rem; } }
    .nav-items .nav-item.border-right {
      border: 0; }
      .nav-items .nav-item.border-right:after {
        content: '';
        position: absolute;
        right: 0.5rem;
        height: 70%;
        top: 15%;
        bottom: 15%;
        width: 2px;
        background-color: var(--bg-color-subtle); }
    .nav-items .nav-item .nav-item-index {
      padding-right: 1.5rem;
      font-family: "Vidaloka", serif;
      color: var(--text-color-subtle);
      font-size: 2.5rem;
      line-height: 28px; }
      @media screen and (min-width: 86em) {
        .nav-items .nav-item .nav-item-index {
          padding-right: 2rem; } }
      .nav-items .nav-item .nav-item-index bdi {
        display: block;
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: 16px;
        margin-top: 0.25rem;
        text-transform: uppercase; }
    .nav-items .nav-item .nav-item-info {
      min-height: 90px; }
      .nav-items .nav-item .nav-item-info p {
        margin: 0;
        line-height: 1.2; }
        .nav-items .nav-item .nav-item-info p.title {
          font-weight: 500;
          font-size: 16px;
          margin: 0.25rem 0; }
        .nav-items .nav-item .nav-item-info p.description {
          font-size: 14px; }
        .nav-items .nav-item .nav-item-info p.category {
          font-size: 11px;
          text-transform: uppercase;
          font-weight: 700;
          color: var(--text-color-subtle); }
    .nav-items .nav-item.current .nav-item-index, .nav-items .nav-item:hover .nav-item-index {
      color: var(--anchor-hover-color); }
    @media screen and (max-width: 64em) {
      .nav-items .nav-item p {
        font-size: 14px; } }
  .nav-items .current-selector {
    position: absolute;
    top: 0;
    height: 5px;
    width: 25%;
    left: 0;
    z-index: 3;
    margin: 0; }
    .nav-items .current-selector:before {
      content: '';
      position: absolute;
      width: calc(100% - 2rem);
      left: 1rem;
      top: 0;
      bottom: 0;
      background-color: var(--orange-color); }
      @media screen and (min-width: 108em) {
        .nav-items .current-selector:before {
          left: 0.5rem; } }
    @media screen and (min-width: 108em) {
      .nav-items .current-selector.video:before {
        width: calc(100% - 3rem); } }

@media screen and (min-width: 108em) {
  .max-width-4 .nav-items .nav-item {
    padding: 0.75rem; } }

.video-preview {
  width: 420px;
  min-width: 320px;
  margin-top: -42px; }
  @media screen and (max-width: 76em) {
    .video-preview {
      bottom: 100%;
      position: absolute;
      right: 0;
      width: 320px; } }
  @media screen and (max-width: 52em) {
    .video-preview {
      position: relative;
      top: auto;
      bottom: auto;
      margin-top: 0;
      width: 100%; } }

.absolute {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 3; }
  .absolute .icon-play {
    position: absolute;
    width: 66px;
    height: 66px;
    line-height: 66px;
    border-radius: 50%;
    left: calc(50% - 33px);
    top: calc(50% - 33px);
    z-index: 3;
    color: rgba(255, 255, 255, 0.85);
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 21px;
    overflow: hidden;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s; }
    .absolute .icon-play:before {
      position: absolute;
      left: calc(50% - 8px);
      top: calc(50% - 8px);
      line-height: 16px; }

main {
  overflow: hidden; }
  main .container {
    display: flex;
    margin: 0 auto;
    width: 64rem;
    max-width: 90vw;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 0; }
    main .container.pt0 {
      padding-top: 0; }
    main .container.pb0 {
      padding-bottom: 0; }
    main .container.py0 {
      padding-top: 0;
      padding-bottom: 0; }
    main .container.left {
      float: none;
      justify-content: left; }
    @media screen and (max-width: 64em) {
      main .container {
        display: block; }
        main .container.full-mobile {
          max-width: 100vw;
          padding-top: 0; }
          main .container.full-mobile .mobile-container {
            max-width: 90vw; } }
  @media screen and (max-width: 64em) {
    main .filter-list-page .container.custom-filters {
      padding: 1rem;
      max-width: 100vw;
      line-height: 1;
      background-color: var(--bg-color);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 80px;
      opacity: 0;
      pointer-events: none;
      opacity: 0;
      transform: translateY(-160px);
      will-change: opacity, transform;
      transition: all 0.3s linear;
      box-shadow: 0 10px 25px 0 var(--dark-shadow); }
      main .filter-list-page .container.custom-filters.fixed {
        pointer-events: all;
        transform: translateY(0);
        z-index: 99;
        opacity: 1; }
      main .filter-list-page .container.custom-filters .month-filter {
        float: right; }
        main .filter-list-page .container.custom-filters .month-filter > button {
          height: calc(80px - 2rem);
          background-color: var(--bg-color);
          width: 100%;
          width: 100%;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          position: absolute; }
          main .filter-list-page .container.custom-filters .month-filter > button i {
            margin-left: 0.5rem;
            font-size: 24px;
            vertical-align: middle; }
        main .filter-list-page .container.custom-filters .month-filter > input[type="month"] {
          position: relative;
          border: 0;
          outline: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
          text-indent: -999px;
          z-index: 2;
          opacity: 0;
          height: calc(80px - 2rem);
          width: 100%; }
          main .filter-list-page .container.custom-filters .month-filter > input[type="month"]::-webkit-calendar-picker-indicator {
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto; }
      main .filter-list-page .container.custom-filters .type-filter > button {
        height: calc(80px - 2rem);
        background-color: var(--bg-color);
        width: 100%;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute; }
        main .filter-list-page .container.custom-filters .type-filter > button i {
          margin-left: 0.5rem;
          font-size: 24px;
          vertical-align: middle; }
      main .filter-list-page .container.custom-filters .type-filter > select {
        position: relative;
        border: 0;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        text-indent: -999px;
        z-index: 2;
        opacity: 0;
        height: calc(80px - 2rem);
        width: 100%; } }
  main .filter-list-page .section-title {
    font-size: 2.4rem; }

.articles-list {
  padding-top: 0;
  padding-bottom: 0; }
  .articles-list.container {
    min-height: 568px; }
    @media screen and (max-width: 64em) {
      .articles-list.container {
        display: block; } }

.box {
  background-color: var(--bg-color);
  border-radius: 4px;
  margin: 1rem;
  padding: 1.5rem 1rem; }
  .box.full {
    padding: 0.5rem 1rem; }
  .box.bg-dark {
    background-color: var(--bg-color-dark); }
  .box:hover {
    box-shadow: 0 10px 25px 0 var(--dark-shadow); }
  .box form {
    margin: 1rem 0 0; }
  .box.box-content {
    margin: 0 auto;
    padding: 2rem; }
    @media screen and (max-width: 64em) {
      .box.box-content {
        padding: 1rem; } }
  .box.highlighted-points {
    padding-right: 0; }
    .box.highlighted-points .title-container {
      padding-right: 1rem; }

.section-title {
  margin: 0;
  display: block;
  font-size: 1.6rem; }
  @media screen and (max-width: 64em) {
    .section-title {
      font-size: 1.2rem; } }
  .section-title a:hover {
    text-decoration: none; }

.section-title-separator {
  border-bottom-color: var(--text-color);
  border-width: 4px;
  margin: 0.5rem 0; }
  @media screen and (max-width: 64em) {
    .section-title-separator {
      border-width: 3px; } }

.section-text {
  font-size: 90%;
  line-height: 1.4;
  padding-left: 5px; }
  .section-text.small {
    font-size: 80%;
    line-height: 1.2; }
  .section-text.big {
    font-size: 120%;
    line-height: 1.6;
    padding-left: 0; }

.form-control {
  padding-bottom: 0.75rem; }
  .form-control.floating-submit input {
    padding-right: 25px;
    height: 24px;
    background-color: transparent; }
    .form-control.floating-submit input.error {
      border-bottom-color: var(--error-color); }
    .form-control.floating-submit input.success {
      border-bottom-color: var(--success-color); }
    .form-control.floating-submit input.ng-not-empty ~ .error {
      display: none; }
    .form-control.floating-submit input.ng-not-empty ~ button[type="submit"] {
      opacity: 1; }
    .form-control.floating-submit input.ng-empty ~ button[type="submit"],
    .form-control.floating-submit input.ng-invalid ~ button[type="submit"] {
      opacity: 0; }
  .form-control.floating-submit button[type="submit"] {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 24px;
    opacity: 0;
    transition: opacity 0.3s; }
    .form-control.floating-submit button[type="submit"] i {
      font-size: 18px; }
  .form-control .feedback {
    font-size: 11px;
    line-height: 1.2;
    position: absolute;
    bottom: -5px;
    left: 5px;
    visibility: hidden; }
  .form-control .error + .feedback {
    visibility: visible;
    color: var(--error-color); }
  .form-control .success + .feedback {
    font-size: 13px;
    visibility: visible;
    color: var(--success-color); }

.column > .box {
  margin-left: 0;
  padding-bottom: 0.25rem; }
  @media screen and (max-width: 52em) {
    .column > .box {
      margin: 0 auto; } }

.social-list .social-btn {
  font-size: 90%;
  border-radius: 4px;
  background-color: var(--bg-color-subtle);
  padding: 0.25rem 0.5rem; }
  .social-list .social-btn > a {
    display: flex;
    align-items: center;
    justify-content: start; }
  .social-list .social-btn img {
    height: 16px;
    margin-right: 0.5rem; }

.card {
  background-color: var(--bg-color);
  margin: 0.5rem 0;
  padding-right: 1rem;
  overflow: hidden; }
  .card.full {
    margin: 0.75rem 0; }
    .card.full:first-child {
      margin-top: 0; }
  .card a,
  .card a:hover {
    text-decoration: none; }
  .card .image-column {
    width: 110px;
    padding-right: 15px;
    flex: none; }
    @media screen and (max-width: 64em) {
      .card .image-column {
        width: 90px;
        padding-right: 10px; } }
  .card h1 {
    line-height: 1.2;
    margin: 0 0 5px;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 95%; }
  .card.full h1 {
    font-family: "Vidaloka", serif;
    font-size: 130%; }
    @media screen and (max-width: 64em) {
      .card.full h1 {
        line-height: 1; } }
  .card.full .image-column {
    width: 200px; }
    @media screen and (max-width: 64em) {
      .card.full .image-column {
        width: 120px; } }
  .card.full .content-column {
    min-height: 185px;
    width: 70%; }
    @media screen and (max-width: 64em) {
      .card.full .content-column {
        min-height: 110px; } }
    .card.full .content-column p.description {
      margin: 0.5rem 0; }
  .card .content-column {
    min-height: 95px;
    padding: 0.25rem 0;
    width: 100%; }
    @media screen and (max-width: 64em) {
      .card .content-column {
        min-height: 80px;
        justify-content: space-between; } }
    .card .content-column > * {
      width: 100%;
      display: block; }
    .card .content-column p {
      line-height: 1.2;
      margin: 0 0 5px; }
      .card .content-column p.category {
        font-weight: 700;
        font-size: 70%;
        text-transform: uppercase;
        letter-spacing: 0.02rem;
        color: var(--text-color-subtle); }
        @media screen and (max-width: 64em) {
          .card .content-column p.category {
            letter-spacing: 0.03rem;
            font-weight: 500;
            color: var(--dark); } }
      .card .content-column p.description {
        color: var(--text-color-subtle);
        margin-bottom: 0.5rem; }
      .card .content-column p.info {
        margin-top: 5px;
        font-weight: 500;
        font-size: 70%;
        line-height: 1.2; }
  .card .social-share-list {
    margin: 0; }
    @media screen and (max-width: 64em) {
      .card .social-share-list {
        display: none; } }
    .card .social-share-list li,
    .card .social-share-list i {
      cursor: pointer; }
    .card .social-share-list .icon-anchor {
      font-size: 18px; }
      .card .social-share-list .icon-anchor:before {
        transform: rotate(-45deg);
        display: inline-block; }
      .card .social-share-list .icon-anchor:hover {
        color: var(--anchor-hover-color); }
  .card .section-title-separator {
    border-width: 2px;
    border-bottom-color: var(--border-color-subtle); }
  .card .key-value-table {
    display: block; }
    .card .key-value-table tbody {
      display: block; }
    @media screen and (min-width: 65em) {
      .card .key-value-table tr {
        display: flex;
        padding-bottom: 0; } }
    .card .key-value-table tr td.table-row-title {
      color: var(--text-color);
      font-weight: 500;
      margin-right: 5px; }

.events-carousel-wrapper {
  width: calc(100% - 1rem);
  overflow: hidden; }
  @media screen and (max-width: 64em) {
    .events-carousel-wrapper {
      width: 100%; } }

.carousel-wrapper {
  display: flex;
  box-sizing: content-box;
  z-index: 1;
  width: 100%;
  height: 100%;
  list-style: none;
  margin: 0 0 0.75rem;
  padding: 0; }
  .carousel-wrapper.disabled a {
    pointer-events: none; }

@media screen and (max-width: 64em) {
  .carousel-navigation {
    display: none; } }

.navigation-dot {
  margin-left: 12px;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: var(--title-color-subtle);
  line-height: 12px;
  cursor: pointer;
  padding: 0; }
  .navigation-dot:before {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    top: -20px;
    bottom: -20px; }
  .navigation-dot.current {
    background-color: var(--dark); }
  .navigation-dot:focus {
    outline-style: dotted;
    outline-width: 0.05em;
    outline-color: rgba(0, 0, 0, 0.3); }
  .navigation-dot:first-child {
    margin-left: 0; }

.navigation-arrow {
  margin-left: 15px;
  margin-right: 15px;
  border: 0;
  width: 24px;
  height: 12px;
  background-color: transparent;
  color: var(--text-color-white);
  line-height: 12px;
  cursor: pointer;
  padding: 0; }
  .navigation-arrow:before {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    top: -20px;
    bottom: -20px; }
  .navigation-arrow i {
    font-size: 21px;
    line-height: 12px;
    color: var(--text-color-white); }
  .navigation-arrow:first-child {
    margin-right: 3px; }
  .navigation-arrow:focus {
    outline-style: dotted;
    outline-width: 0.05em;
    outline-color: rgba(0, 0, 0, 0.4); }

.image-overlay {
  line-height: 0; }
  .image-overlay:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(26, 26, 26, 0.39);
    transition: opacity 0.3s ease-out;
    opacity: 0; }

.vertical-card {
  width: calc(33.3333% - 0.5rem);
  min-width: 205px;
  margin-right: 0.75rem;
  flex-shrink: 0; }
  .vertical-card a {
    height: 100%;
    background-color: var(--title-color-subtle);
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none; }
  .vertical-card:first-child a {
    background-color: var(--dark); }
  .vertical-card.draggable {
    cursor: grab; }
    .vertical-card.draggable a {
      cursor: inherit;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none; }
  .vertical-card img {
    pointer-events: none; }
  .vertical-card .card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem 1rem;
    transition: opacity 0.6s ease-out, transform 0.4s;
    transform: translate3d(0, 70%, 0);
    opacity: 0; }
    .vertical-card .card-content h1 {
      color: var(--text-color-white);
      font-size: 18px;
      margin: 0 0 0.25rem;
      line-height: 1; }
    .vertical-card .card-content p {
      color: var(--text-color-white);
      font-size: 14px;
      margin: 0;
      line-height: 1; }
  .vertical-card:hover .image-overlay:after, .vertical-card.current .image-overlay:after {
    opacity: 1; }
  .vertical-card:hover .card-content, .vertical-card.current .card-content {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

footer .vertical-separator {
  background-color: var(--title-color-subtle);
  width: 2px;
  margin: 0 10px;
  display: block;
  height: 100%;
  margin: 0 auto; }

footer .copyright {
  text-transform: uppercase; }
  footer .copyright .section-text {
    font-size: 13px;
    letter-spacing: 0.01rem; }
    @media screen and (max-width: 64em) {
      footer .copyright .section-text {
        text-align: center;
        max-width: 200px;
        margin: 0 auto; } }

@media screen and (max-width: 64em) {
  footer {
    padding: 0 1rem; }
    footer > div {
      margin: 0.5rem auto; } }

.key-value-table tr {
  display: block;
  padding-bottom: 0.5rem; }
  .key-value-table tr td {
    display: block;
    padding: 0.1rem 0; }
    .key-value-table tr td.table-row-title {
      font-weight: 500;
      font-size: 13px;
      color: var(--text-color-subtle);
      text-transform: uppercase; }
    .key-value-table tr td.section-text {
      font-size: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 90%; }

.app-info {
  min-height: 420px;
  border-radius: 4px;
  margin: 0 1rem 1rem 0;
  padding: 1.5rem;
  color: var(--text-color-white);
  overflow: hidden; }
  @media screen and (max-width: 52em) {
    .app-info {
      margin: 0 0 1rem; } }
  .app-info > * {
    width: 50%; }
  .app-info hr {
    background-color: var(--bg-color);
    border-bottom-width: 0;
    margin: 0.5rem 0;
    height: 4px;
    width: 0;
    transition-delay: 0.8s;
    transition: width 0.2s ease-out;
    transform-origin: 0 50%; }
  .app-info .section-title {
    font-size: 1.8rem;
    line-height: 1.1;
    max-width: 50%;
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    transition-delay: 0.8s;
    transition: opacity 1s ease-out, transform 0.4s; }
    @media screen and (max-width: 64em) {
      .app-info .section-title {
        font-size: 1.6rem; } }
  .app-info .section-text {
    font-size: 16px;
    max-width: 50%;
    opacity: 0;
    transition-delay: 1.8s;
    transition: opacity 1.2s ease-out; }
    .app-info .section-text a {
      color: inherit;
      font-weight: 600; }
  .app-info .image {
    position: absolute;
    right: 0;
    top: 10%;
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    transition-delay: 1.8s;
    transition: opacity 0.4s, transform 1s; }
  .app-info.on-screen hr {
    width: 100%; }
  .app-info.on-screen .section-title,
  .app-info.on-screen .image {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .app-info.on-screen .section-text {
    opacity: 1; }

.points-tree .section-title {
  margin: 1rem 0; }

.page-title {
  font-size: 3.4rem;
  line-height: 1;
  border-bottom: 5px solid var(--text-color);
  padding-bottom: 1rem;
  margin: 0.5rem 0; }
  @media screen and (max-width: 64em) {
    .page-title {
      font-size: 1.8rem;
      line-height: 1.2;
      border-bottom-width: 3px; } }

@media screen and (max-width: 64em) {
  .description {
    font-size: 15px; } }

.square-card {
  display: block; }
  .square-card .card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem 1rem;
    transition: opacity 0.6s ease-out, transform 0.4s;
    transform: translate3d(0, 70%, 0);
    opacity: 0; }
    .square-card .card-content h1 {
      color: var(--text-color-white);
      font-size: 18px;
      margin: 0 0 0.5rem;
      line-height: 1; }
    .square-card .card-content p {
      color: var(--text-color-white);
      font-size: 14px;
      margin: 0;
      line-height: 1; }
    .square-card .card-content .section-separator {
      background-color: var(--bg-color);
      border-bottom-width: 0;
      margin: 0.25rem 0;
      height: 3px;
      width: 0;
      transition: width 0.2s ease-out;
      transition-delay: 0.2s;
      transform-origin: 0 50%; }
    .square-card .card-content .fake-anchor {
      font-size: 11px;
      text-transform: uppercase;
      color: var(--text-color-white);
      font-weight: 700; }
  .square-card:hover .image-overlay:after {
    opacity: 1; }
  .square-card:hover .card-content {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .square-card:hover .section-separator {
    width: 100%; }

.video-banner {
  overflow: hidden; }
  .video-banner.fit video {
    min-width: 100vw !important;
    min-height: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%; }
  .video-banner video {
    object-fit: cover; }
  .video-banner .controls-overlay {
    cursor: pointer;
    left: 0;
    top: 0; }
    .video-banner .controls-overlay.playing {
      z-index: -1; }
      .video-banner .controls-overlay.playing i {
        opacity: 0; }

.banner {
  width: 100vw;
  overflow: hidden; }

.gallery-container {
  background-color: var(--bg-color-dark);
  display: flex;
  box-sizing: content-box;
  z-index: 1;
  width: 100%;
  height: 100%;
  list-style: none;
  padding: 0; }
  @media screen and (max-width: 64em) {
    .gallery-container {
      margin-bottom: 60px; } }
  .gallery-container .psv-loader-container .psv-loader {
    display: none !important; }
  .gallery-container .gallery-item {
    width: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
    .gallery-container .gallery-item .slide-img {
      background-size: cover;
      background-position: center; }
    .gallery-container .gallery-item .icon-panorama {
      position: absolute;
      color: var(--text-color-white);
      font-size: 24px;
      left: 5%;
      top: calc(100% - 45px);
      width: 25px;
      height: 25px; }

.gallery-navigation {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3; }
  @media screen and (max-width: 64em) {
    .gallery-navigation {
      bottom: 25px; } }
  .gallery-navigation .navigation-dot {
    background-color: transparent;
    border: 1px solid var(--bg-color); }
    @media screen and (max-width: 64em) {
      .gallery-navigation .navigation-dot {
        border-color: var(--text-color); } }
    .gallery-navigation .navigation-dot.current {
      background-color: var(--bg-color); }
      @media screen and (max-width: 64em) {
        .gallery-navigation .navigation-dot.current {
          background-color: var(--bg-color-dark); } }
    .gallery-navigation .navigation-dot label {
      color: var(--text-color-white);
      text-align: center;
      position: absolute;
      right: calc(50% - 10px);
      bottom: -15px;
      font-weight: 700;
      font-size: 10px; }
  .gallery-navigation button:first-child {
    margin-left: 0; }
  @media screen and (max-width: 64em) {
    .gallery-navigation .navigation-arrow {
      width: 75px;
      height: 75px;
      position: absolute;
      top: calc(50% - 75px);
      line-height: 75px;
      z-index: 3;
      padding: 0 10px;
      margin: 0;
      outline: none; }
      .gallery-navigation .navigation-arrow i {
        color: var(--text-color); } }

.single header {
  padding: 1.5rem 4rem 1.5rem 0; }
  @media screen and (max-width: 64em) {
    .single header {
      padding-right: 2rem; } }
  @media screen and (max-width: 52em) {
    .single header {
      padding-right: 0; } }
  .single header .section-title {
    font-size: 3rem;
    line-height: 1; }
    @media screen and (max-width: 64em) {
      .single header .section-title {
        font-size: 2.1rem; } }
  .single header .share-section ul {
    margin: 0 0 1rem; }
  .single header .date,
  .single header .category {
    font-size: 14px;
    font-weight: 500; }

.single .main-content {
  padding-top: 1rem; }
  @media screen and (max-width: 64em) {
    .single .main-content > div {
      padding-right: 2rem; } }
  @media screen and (max-width: 52em) {
    .single .main-content > div {
      padding-right: 0;
      padding-bottom: 1rem; } }

@media screen and (max-width: 64em) {
  .single-point .main-content .container {
    padding-top: 0; } }

@media screen and (max-width: 64em) {
  .nucleus .banner .navigation-arrow {
    width: 75px;
    height: 75px;
    position: absolute;
    top: calc(50% - 75px);
    z-index: 3;
    padding: 0 10px;
    margin: 0;
    outline: none; }
    .nucleus .banner .navigation-arrow i {
      font-size: 32px;
      vertical-align: middle; }
    .nucleus .banner .navigation-arrow.navigate-prev {
      text-align: left; }
    .nucleus .banner .navigation-arrow.navigate-next {
      right: 0;
      text-align: right; } }

@media screen and (max-width: 64em) {
  .nucleus .main-content .container {
    padding-top: 0; } }

@media screen and (max-width: 64em) {
  .nucleus .bg-subtle {
    background-color: var(--bg-color); } }

.nucleus .bg-subtle.sub-container {
  background-color: var(--bg-color); }

.share-section .share-btn {
  cursor: pointer; }
  .share-section .share-btn:after {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    top: -20px;
    bottom: -20px; }

.share-section label {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  margin-right: 0.75rem;
  color: var(--text-color-subtle); }

.share-section ul li:not(:first-child) {
  margin-left: 0.4rem; }

.content aside .key-value-table tr {
  padding: 0.75rem 0; }
  .content aside .key-value-table tr:not(:last-child) {
    border-bottom: 1px solid var(--border-color-subtle); }
  .content aside .key-value-table tr:last-child {
    padding-bottom: 0; }

aside .box .card .image-column {
  width: 90px;
  padding-right: 10px; }

aside .box .card:not(.last) {
  margin-bottom: 1rem; }
  aside .box .card:not(.last):after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.25rem;
    height: 1px;
    background-color: var(--dark-shadow); }

.vertical-filters {
  list-style: none;
  padding-left: 0; }
  .vertical-filters li {
    margin-bottom: 0.5rem; }
    .vertical-filters li a {
      font-family: "Vidaloka", serif;
      text-transform: capitalize;
      font-size: 120%;
      color: var(--text-color); }
      .vertical-filters li a:hover {
        color: var(--anchor-hover-color); }
      .vertical-filters li a.disabled {
        cursor: default;
        color: var(--text-color-subtle);
        pointer-events: none; }
        .vertical-filters li a.disabled:hover {
          color: var(--text-color-subtle); }
    .vertical-filters li.current a {
      color: var(--anchor-hover-color); }
    .vertical-filters li.permanent {
      margin-bottom: 2rem; }
      .vertical-filters li.permanent a:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1rem;
        height: 1px;
        background-color: var(--dark-shadow); }

.image-label p {
  margin: 1rem 0 0;
  line-height: 1.2; }

.years-carousel-container {
  width: 145px;
  overflow: hidden; }

.years-carousel {
  padding-left: 0;
  width: auto;
  display: flex;
  box-sizing: content-box;
  z-index: 1;
  width: 100%;
  list-style: none; }
  .years-carousel li {
    display: inline-block;
    text-align: center;
    margin: 0 2px;
    min-width: 45px;
    color: var(--text-color-subtle); }
    .years-carousel li.current {
      color: var(--dark);
      font-weight: 700; }
    .years-carousel li a {
      color: inherit;
      font-weight: inherit;
      cursor: pointer; }
      .years-carousel li a:hover {
        color: var(--dark);
        font-weight: 700; }

.nucleus .slide-container {
  box-sizing: content-box;
  z-index: 1;
  width: 100%;
  height: 100%;
  list-style: none;
  padding: 0; }
  @media screen and (max-width: 64em) {
    .nucleus .slide-container {
      flex-wrap: wrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; } }

.nucleus .nucleus-column {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  min-height: 650px;
  width: 33.33%;
  will-change: width, transform;
  transition: all 0.2s linear;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translateY(0); }
  @media screen and (max-width: 64em) {
    .nucleus .nucleus-column {
      min-height: 186px;
      max-width: 100%;
      width: 100%; } }
  .nucleus .nucleus-column .background {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.2s linear;
    transform: scale(1);
    will-change: transform, filter; }
  .nucleus .nucleus-column:hover .background {
    transform: scale(1.1);
    filter: blur(2px);
    -webkit-filter: blur(2px); }
  .nucleus .nucleus-column > .content {
    color: var(--text-color-white);
    padding: 2rem 10%;
    width: 100%; }
    @media screen and (max-width: 64em) {
      .nucleus .nucleus-column > .content {
        -webkit-box-pack: center;
        justify-content: center; } }
    .nucleus .nucleus-column > .content:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4); }
  .nucleus .nucleus-column .title {
    font-size: 3.2rem;
    max-width: 375px;
    margin: 0; }
    @media screen and (max-width: 64em) {
      .nucleus .nucleus-column .title {
        font-size: 1.8rem;
        line-height: 1.2;
        max-width: 210px;
        margin: 0 auto;
        text-align: center; } }
  .nucleus .nucleus-column .section-title-separator {
    border-color: var(--white); }
    @media screen and (max-width: 64em) {
      .nucleus .nucleus-column .section-title-separator {
        display: none; } }
  .nucleus .nucleus-column .excerpt {
    font-size: 18px;
    line-height: 21px;
    min-height: 90px;
    margin-bottom: 2rem; }
  @media screen and (max-width: 64em) {
    .nucleus .nucleus-column .icon {
      display: none; } }

.nucleus-submenu {
  background-color: transparent;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500; }
  @media screen and (max-width: 64em) {
    .nucleus-submenu {
      padding-right: 0;
      padding-left: 2rem;
      margin: 0;
      width: 100vw;
      margin-left: -2.5rem;
      font-size: 14px; }
      .nucleus-submenu > ul {
        justify-content: flex-start;
        overflow-x: scroll;
        white-space: nowrap;
        padding: 0; }
        .nucleus-submenu > ul li {
          display: inline-block;
          vertical-align: top;
          margin: 0 0.5rem; } }
  .nucleus-submenu li a.active:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--anchor-hover-color); }

.contacts-map {
  min-height: 450px;
  width: 100%; }

.map-element {
  height: 100%;
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.absolute-filters {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--dark);
  padding: 0.75rem 2rem;
  min-height: 50px;
  z-index: 99; }
  @media screen and (min-width: 64em) {
    .absolute-filters {
      display: none; } }
  .absolute-filters select {
    position: absolute;
    left: 0;
    width: 100vw;
    top: 0;
    bottom: 0;
    text-indent: -999px;
    overflow: hidden;
    z-index: 2; }
  .absolute-filters #currentCategory {
    font-size: 21px;
    font-family: "Vidaloka", serif;
    color: var(--white); }
  .absolute-filters:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background-color: var(--anchor-hover-color); }
  .absolute-filters:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 50px;
    width: 50px;
    font-size: 32px;
    text-align: center;
    background-color: var(--anchor-hover-color);
    font-family: "alter-chao" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    color: var(--white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.watermark {
  position: fixed;
  left: 0;
  right: 0;
  top: 60px;
  font-family: "Vidaloka", serif;
  color: var(--text-color);
  font-size: 23vw;
  line-height: 1;
  text-align: center;
  opacity: 0.1; }
  @media screen and (max-width: 64em) {
    .watermark {
      top: 80px; } }

.search .search-form {
  padding-bottom: 0; }
  .search .search-form input {
    border: 0;
    box-shadow: none;
    width: 100%;
    padding: 1.25rem 0 2.75rem;
    font-size: 18px;
    outline: none; }
  .search .search-form:after {
    content: '';
    position: absolute;
    left: 0;
    height: 3px;
    right: 0;
    top: 100%;
    background-color: var(--bg-color-dark); }
  .search .search-form > button.form-control-submit {
    line-height: 21px;
    display: inline-block;
    top: calc(50% - 21px); }
    .search .search-form > button.form-control-submit i {
      font-size: 21px; }
  .search .search-form .feedback {
    font-size: 14px;
    bottom: 5px;
    left: 0; }

.search .container {
  margin-top: 0;
  padding-bottom: 0; }
  .search .container.grid {
    min-height: 450px;
    padding-top: 0.5rem; }
    @media screen and (max-width: 64em) {
      .search .container.grid {
        min-height: 200px; } }
  .search .container .box {
    padding: 1.5rem;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0; }

.search ul.filters {
  margin-top: 1.5rem; }
  @media screen and (max-width: 64em) {
    .search ul.filters {
      display: block;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      overflow-x: scroll;
      white-space: nowrap;
      padding-right: 0;
      padding-left: 1rem;
      margin: 0;
      width: 100vw;
      margin-left: -1.5rem; }
      .search ul.filters li {
        margin: 10px 7px;
        display: inline-block; } }
  .search ul.filters a.active {
    color: var(--anchor-hover-color);
    font-weight: 500; }
  .search ul.filters a span {
    font-weight: 500; }

.search .card.full {
  padding: 0 1rem; }
  .search .card.full .image-column {
    width: 100px; }
  .search .card.full .content-column {
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .search .card.full h1 {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 95%;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0; }
  .search .card.full p {
    margin: 0 0 5px; }
  .search .card.full .content-column {
    min-height: 95px; }

.search .no-results {
  padding: 0; }

.search .pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center; }
  .search .pagination li {
    margin: 0 5px; }
    .search .pagination li a {
      font-weight: 500; }
    .search .pagination li.active a, .search .pagination li:hover:not(.disabled) a {
      color: var(--anchor-hover-color); }
    .search .pagination li.disabled a {
      pointer-events: none;
      opacity: 0.5; }
    .search .pagination li:first-child, .search .pagination li:last-child {
      margin: 0 8px; }
      .search .pagination li:first-child a, .search .pagination li:last-child a {
        font-size: 26px; }

.outdoor-map .map-navigation {
  overflow: hidden; }
  @media screen and (max-width: 64em) {
    .outdoor-map .map-navigation .container {
      padding: 0;
      width: 100vw;
      max-width: none;
      margin: 0; } }
  @media screen and (max-width: 64em) {
    .outdoor-map .map-navigation ul {
      display: flex;
      box-sizing: content-box;
      z-index: 1;
      overflow: auto;
      white-space: nowrap;
      min-width: 100%;
      height: 100%;
      list-style: none;
      margin: 0; } }
  @media screen and (max-width: 64em) {
    .outdoor-map .map-navigation ul li {
      display: inline-block;
      padding: 2rem 0; } }
  .outdoor-map .map-navigation ul li a {
    color: var(--text-color-white);
    font-family: "Vidaloka", serif;
    font-size: 140%;
    margin: 0 1rem;
    opacity: 0.6; }
    .outdoor-map .map-navigation ul li a:hover, .outdoor-map .map-navigation ul li a.active {
      opacity: 1; }
