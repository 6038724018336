@keyframes border {
    0% {
        box-shadow: 60px -60px 0 2px rgba(0, 0, 0, 0.3), -60px -60px 0 2px rgba(0, 0, 0, 0.3), -60px 60px 0 2px rgba(0, 0, 0, 0.3), 60px 60px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #E94E3D;
    }

    25% {
        box-shadow: 0 -125px 0 2px rgba(0, 0, 0, 0.3), -60px -60px 0 2px rgba(0, 0, 0, 0.3), -60px 60px 0 2px rgba(0, 0, 0, 0.3), 60px 60px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff;
    }

    50% {
        box-shadow: 0 -125px 0 2px rgba(0, 0, 0, 0.3), -125px 0px 0 2px rgba(0, 0, 0, 0.3), -60px 60px 0 2px rgba(0, 0, 0, 0.3), 60px 60px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff;
    }

    75% {
        box-shadow: 0 -125px 0 2px rgba(0, 0, 0, 0.3), -125px 0px 0 2px rgba(0, 0, 0, 0.3), 0px 125px 0 2px rgba(0, 0, 0, 0.3), 60px 60px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff;
    }

    100% {
        box-shadow: 0 -125px 0 2px rgba(0, 0, 0, 0.3), -125px 0px 0 2px rgba(0, 0, 0, 0.3), 0px 125px 0 2px rgba(0, 0, 0, 0.3), 120px 40px 0 2px rgba(0, 0, 0, 0.3), 0 0 0 2px #fff;
    }
}


@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}