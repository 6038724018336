[ng\:cloak],
[ng-cloak],
.ng-cloak {
    display: none !important;
}

* {
    position: relative;
    box-sizing: border-box;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

body {
    font-family: $text-font;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    color: var(--text-color);
    background-color: var(--bg-color)
}

img {
    max-width: 100%;
    height: auto
}

svg {
    max-height: 100%
}

a {
    color: var(--anchor-color);
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: var(--anchor-hover-color)
    }
}

.invisible {
    opacity: 0;
}

a.underline,
.text a {
    text-decoration: underline;
}

.bold-underline {
    border-bottom: 1px solid var(--dark);
}

a.bold-underline:hover {
    border-color: var(--anchor-hover-color);
}

.uppercase {
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title-font;
    font-weight: 400;
    line-height: 1.25;
    margin-top: 1em;
    margin-bottom: .5em
}

h1 {
    font-size: 2rem
}

h2 {
    font-size: 1.5rem
}

h3 {
    font-size: 1.25rem
}

h4 {
    font-size: 1rem
}

h5 {
    font-size: .875rem
}

h6 {
    font-size: .75rem
}

a:hover {
    color: var(--anchor-hover-color);
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    text-decoration: underline
}

blockquote,
dl,
ol,
p,
pre,
ul {
    margin-top: 1em;
    margin-bottom: 1em
}

code,
pre,
samp {
    font-family: Roboto Mono, Source Code Pro, Menlo, Consolas, Liberation Mono, monospace
}

code,
samp {
    padding: .125em
}

code,
pre,
samp {
    font-size: 87.5%
}

pre {
    overflow: scroll
}

blockquote {
    font-size: 1.25rem;
    font-style: italic;
    margin-left: 0
}

hr {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--border-color-subtle)
}

button {
    &.reset {
        cursor: pointer;
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;

        background: transparent;

        color: inherit;
        font: inherit;

        line-height: normal;

        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;

        -webkit-appearance: none;
    }

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}


.border-darken {
    border-color: var(--border-color)
}

.border-subtle {
    border-color: var(--border-color-subtle)
}

.black {
    color: var(--orange-color)
}

.white {
    color: var(--orange-color)
}

.gray {
    color: var(--orange-color)
}

.bg-white {
    background-color: var(--bg-color)
}

.bg-subtle {
    background-color: var(--bg-color-subtle)
}

.bg-dark {
    background-color: var(--bg-color-dark)
}

.orange {
    color: var(--orange-color)
}

.border-orange {
    border-color: var(--orange-color)
}

.vida-loka {
    font-family: 'Vidaloka', serif;
}

.plex-sans {
    font-family: 'IBM Plex Sans', sans-serif;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.label {
    font-size: .875rem;
    font-weight: 700;
    display: block;
    margin-bottom: .5rem
}

.input,
.select {
    height: 2.5rem
}

.input,
.select,
.textarea {
    font-family: inherit;
    font-size: inherit;
    display: block;
    width: 100%;
    padding: .5rem;
    margin-bottom: 1rem;
    border: 1px solid var(--border-color-subtle);
    border-radius: 3px
}

pre {
    padding-left: 1rem;
    border-left: 3px solid var(--border-color)
}

.color-inherit {
    color: inherit
}

.border-minimal {
    border-width: 1px;
}

.border-thick {
    border-width: 3px
}


[data-aspect-ratio] {
    display: block;
    max-width: 100%;
    position: relative;

    &:before {
        content: '';
        display: block;
    }

    >* {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }
}

[data-aspect-ratio="21:9"]:before {
    padding-top: 42.85%;
}

[data-aspect-ratio="16:9"]:before {
    padding-top: 56.25%;
}

[data-aspect-ratio="3:1"]:before {
    padding-top: 33.33%;
}

[data-aspect-ratio="3:2"]:before {
    padding-top: 66.66%;
}

[data-aspect-ratio="4:3"]:before {
    padding-top: 75%;
}

[data-aspect-ratio="1:1"]:before {
    padding-top: 100%;
}

[data-aspect-ratio="portrait"]:before {
    padding-top: 67%;
}

form {
    .form-control {
        input {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            box-shadow: none;
            width: 100%;
            padding: 0.25rem;
            font-size: 100%;
            font-family: $text-font;
            color: var(--text-color);
            outline: none;
        }
    }
}

.negative-mx1 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: calc(100% + 1rem);
}

.colored,
.colored>a {
    color: var(--anchor-hover-color);
}

#outdated {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 170px;
    text-align: center;
    text-transform: uppercase;
    z-index: 1500;
    background-color: #f25648;
    color: var(--text-color-white);
}

* html #outdated {
    position: absolute;
}

#outdated h6 {
    margin: 30px 0 10px;
    font-size: 2.3vw;
    line-height: 1;
    letter-spacing: 0.02rem;
}

#outdated p {
    font-size: 16px;
    line-height: 1.2;
    margin: 10px 0;
}

#outdated #btnUpdateBrowser {
    display: block;
    position: relative;
    padding: 10px 20px;
    margin: 10px auto;
    font-size: 14px;
    width: 230px;
    color: var(--text-color-white);
    text-decoration: none;
    border: 2px solid var(--text-color-white);
    cursor: pointer;
}

#outdated #btnUpdateBrowser:hover {
    color: #f25648;
    background-color: var(--text-color-white);
}

#outdated .last {
    position: absolute;
    top: 10px;
    right: 25px;
    width: 20px;
    height: 20px;
}

#outdated #btnCloseUpdateBrowser {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: var(--text-color-white);
    font-size: 36px;
}