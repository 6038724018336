$icomoon-font-family: "alter-chao" !default;
$icomoon-font-path: "../fonts" !default;

$icon-anchor: "\e90d";
$icon-play: "\e90c";
$icon-globe: "\e90b";
$icon-triangle-down: "\e90a";
$icon-triangle-up: "\e901";
$icon-arrow-back-long: "\e900";
$icon-arrow-forward: "\e902";
$icon-small-arrow-left: "\e903";
$icon-small-arrow-right: "\e904";
$icon-language: "\e905";
$icon-search: "\e906";
$icon-whatsapp: "\e907";
$icon-twitter: "\e908";
$icon-facebook: "\e909";
$icon-panorama: "\e90e";


@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tsudea');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tsudea#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?tsudea') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?tsudea') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?tsudea##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #4a4a4a;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
        color: inherit;
    }
}

.icon-anchor {
    &:before {
        content: $icon-anchor;
    }
}

.icon-arrow-back-long {
    &:before {
        content: $icon-arrow-back-long;
    }
}

.icon-arrow-forward-long {
    display: inline-block;
    transform: rotate(-180deg);

    &:before {
        content: $icon-arrow-back-long;
    }
}

.icon-globe {
    &:before {
        content: $icon-globe;
    }
}

.icon-triangle-down {
    &:before {
        content: $icon-triangle-down;
    }
}

.icon-triangle-up {
    &:before {
        content: $icon-triangle-up;
    }
}

.icon-triangle-right {
    &:before {
        content: $icon-triangle-up;
    }

    transform: rotate(90deg);
}

.icon-triangle-left {
    &:before {
        content: $icon-triangle-up;
    }

    transform: rotate(-90deg);
}

.icon-arrow-forward {
    &:before {
        content: $icon-arrow-forward;
    }
}

.icon-small-arrow-left {
    &:before {
        content: $icon-small-arrow-left;
    }
}

.icon-small-arrow-right {
    &:before {
        content: $icon-small-arrow-right;
    }
}

.icon-language {
    &:before {
        content: $icon-language;
    }
}

.icon-search {
    &:before {
        content: $icon-search;
    }
}

.icon-whatsapp {
    &:before {
        content: $icon-whatsapp;
    }
}

.icon-twitter {
    &:before {
        content: $icon-twitter;
    }

    &.colored {
        color: #1da1f2;
    }
}

.icon-facebook {
    &:before {
        content: $icon-facebook;
    }

    &.colored {
        color: #3B5998;
    }
}


.icon-play {
    &:before {
        content: $icon-play;
    }
}


.icon-panorama {
    &:before {
        content: $icon-panorama; 
    }
}