@import "variables";
@import "base";
@import "icons";
@import "animations";


.global {
    &.mobile {
        overflow-x: hidden;
    }
}

table {
    border-collapse: separate;
    border-spacing: 0;
    max-width: 100%;
    width: 100%
}

td,
th {
    padding: .5em 0;
    line-height: inherit
}

th {
    text-align: left;
    font-weight: 700;
    vertical-align: bottom
}

td {
    vertical-align: top
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animated.infinite {
    animation-iteration-count: infinite;
}

.animated.delay-1s {
    animation-delay: 1s;
}

.btn {
    font-family: inherit;
    font-size: .875rem;
    font-weight: 700;
    text-decoration: none;
    height: 2.5em;
    margin: 0;
    padding: .5em 1em;
    border: 0;
    border-radius: 3px;
    color: var(--text-color);
    background-color: var(--bg-color-subtle)
}

.app {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

main.container {
    >div:not(.home) {
        margin-top: 80px;
    }    
}

[ui-view] {
    &.ng-leave {
        animation: fadeOut 0.25s; 
    }
  
    &.ng-enter {
        animation: fadeIn 0.25s;
    }
}

.navbar-container {
    @media screen and (max-width: 72em) {
        max-width: 100vw;
        padding-right: 1rem;
    }
}

.navbar {

    .navbar-language,
    .navbar-search {
        min-width: 50px;
        padding: 0;

        >label {
            @media screen and (min-width: 64em) {
                line-height: 26px;
                display: inline-block;
            }
        }
    }

    .navbar-main {
        min-width: 920px;
        
        a {
            padding: 0 1rem;
            text-transform: uppercase;
            font-size: 100%;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: 0;
            text-align: center;

            &.active {
                color: var(--anchor-hover-color);
            }
        }

        img {
            margin: 0 2rem;
            max-width: 120px;
        }

        .dropdown {
            a {
                padding: 0.25rem;
                line-height: 1.6;
                text-transform: none;

                &:hover {
                    opacity: 1;
                }
            }

            .dropdown {
                left: calc(100% + 1.75rem);
                margin: 0;
                top: 0;

                &:before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: auto;
                    right: 100%;
                    width: 2rem;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    >div {
        @media screen and (max-width: 64em) {
            line-height: 60px;
            vertical-align: middle;
            display: inline-block;
        }
    }

    .navbar-search {
        >label {
            z-index: 2;

            @media screen and (max-width: 64em) {
                z-index: 2;
                vertical-align: middle;
                line-height: 1;
                display: inline-block;

                .icon-search {
                    font-size: 30px;
                }
            }
        }

        .icon-search {
            font-size: 21px;
            cursor: pointer;
        }

        .search-container {
            position: absolute;
            line-height: 0;
            left: calc(50% + 20px);
            top: 0;
            width: 0;
            overflow: hidden;
            transition: width 0.2s ease-in-out;

            &.active {
                width: 165px;
            }

            button {
                position: absolute;
                top: calc(50% - 10px);
                right: 0;
                z-index: 3;
                opacity: 0;
                transition: opacity 0.3s;
            }

            input {
                background-color: transparent;
                box-shadow: none;
                line-height: 1;
                padding: 5px 5px 5px 25px;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                width: 150px;
                font-size: 100%;
                float: left;
                outline: 0;

                &.ng-valid~button#triggerSearch {
                    opacity: 1;
                }

                &.ng-invalid~button#triggerSearch,
                &.ng-empty~button#triggerSearch {
                    opacity: 0;
                }
            }
        }
    }

    .navbar-language {
        label#currentLang {
            padding: 0 0.5rem;
        }
    }

    .hamburguer {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 25px;
        height: 25px;
        cursor: pointer;
        transition-timing-function: linear;
        transition-duration: .15s;
        transition-property: opacity, filter;
        text-transform: none;
        color: inherit;
        border: 0;
        padding: 0;
        background-color: transparent;

        &:focus {
            outline-style: dotted;
            outline-width: 0.05em;
            outline-color: rgba(0, 0, 0, 0.3);
        }

        &:before {
            content: '';
            position: absolute;
            height: 60px;
            width: 60px;
            top: calc(50% - 25px);
            left: calc(50% - 25px);
        }

        .hamburguer-inner {
            position: absolute;
            width: 25px;
            height: 3px;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
            border-radius: 3px;
            background-color: var(--text-color);
            top: 5px;

            &:after {
                content: '';
                position: absolute;
                width: 25px;
                height: 3px;
                left: 0;
                border-radius: 3px;
                background-color: var(--text-color);
                top: 10px;
                transition-timing-function: ease;
                transition-duration: .15s;
                transition-property: transform;
            }
        }
    }
}

.main-menu {
    background-color: var(--bg-color);
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    height: 80px;

    &.no-bg {
        background-color: transparent;

        .navbar .navbar-search .search-container input {
            color: var(--text-color-white);
            font-weight: 500;
        }

        ::placeholder {
            color: var(--text-color-white);
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            color: var(--text-color-white);
        }

        ::-ms-input-placeholder {
            color: var(--text-color-white);
        }
    }

    &.active-menu {
        background-color: var(--bg-color-darker);

        .navbar .hamburguer-inner {
            transition-delay: 75ms;
            transform: translate3d(0, 10px, 0) rotate(45deg);

            &:after {
                transition-delay: 75ms;
                transform: translate3d(0, -10px, 0) rotate(-90deg);
            }
        }
    }

    &.fixed:not(.no-bg) {
        position: fixed;

        +main {
            margin-top: 80px;
        }
    }
}

.no-bg .navbar-language>.navbar-item>a label,
.no-bg .navbar .navbar-main a,
.no-bg .navbar i {
    color: var(--text-color-white);
}

.no-bg .navbar .navbar-main a.active {
    font-weight: 700;
}

.no-bg .navbar .navbar-main a:hover {
    color: var(--anchor-hover-color);
}

.no-bg .navbar .hamburguer .hamburguer-inner,
.no-bg .navbar .hamburguer .hamburguer-inner:after {
    background-color: var(--bg-color);
}

li.navbar-item {
    display: flex;

    @media screen and (max-width: 64em) {
        max-width: 300px;
        margin: 1rem auto;
    }

    &:hover {
        >.dropdown {
            width: 16em;
            left: 50%;
            margin: 0 0 0 -8em;
            padding-top: 10px;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transition: visibility 0s, opacity 0.3s;
            z-index: 33;

            &.large {
                width: 18em;
                margin: 0 0 0 -9em;
            }
        }
    }
} 

.dropdown {
    position: absolute;
    display: block;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    text-align: left;
    pointer-events: none;
    transition: visibility 0s, opacity 0s;
    background-color: var(--bg-color);
    top: calc(100% + 3rem);
    z-index: 33;
    padding: 0.5rem 1rem;
    box-shadow: 0 15px 25px 0 var(--dark-shadow);

    &:after {
        bottom: 100%;
        content: '';
        position: absolute;
        pointer-events: none;
        width: 0;
        left: calc(50% - 5px);
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--text-color-white);
        z-index: 3;
    }

    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 100%;
        left: 0;
        height: 3rem;
        width: 100%;
    }

    li {
        width: 100%;

        a {
            width: 100%;
            padding: 0.5rem 0.25rem;
            font-size: 100%;
            
            label {
                color: var(--text-color);
            }

            * {
                cursor: pointer;
            }

            i {
                font-size: 18px;
                right: -10px;
            }

            &.border-subtle {
                border-color: var(--border-color-subtle)
            }
        }

        &:last-child a {
            border-bottom: 0;
        }
    }

    .border-subtle {
        border-color: rgba(0, 0, 0, 0.1);
    }

    &.languages {
        a.current {
            label {
                font-weight: 500;
            }
        }

        .round-flag {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            overflow: hidden;

            >img {
                width: 40px;
                height: 40px;
                max-width: none;
                position: absolute;
                left: calc(50% - 20px);
                top: calc(50% - 20px);

                &.pt,
                &.es {
                    left: calc(50% - 18px)
                }
            }
        }
    }
}

.menu-overlay {
    position: absolute;
    z-index: 98;
    top: 0;
    right: 0;
    left: 0;
    height: calc(100vh - 80px);
    background-color: var(--bg-color-darker);
    margin-top: 80px;
    pointer-events: none;
    opacity: 0;
    overflow-y: auto;

    >div {
        overflow-y: auto;
    }

    a {
        color: var(--text-color-white);
    }

    .background-text {
        font-size: 40vw;
        position: absolute;
        left: 0;
        right: 0;
        top: -10px;
        text-align: center;
        font-family: $title-font;
    }

    .languages {
        a {
            &.current {
                font-weight: 500;
                border-bottom: 2px solid var(--anchor-hover-color);
            }
        }
    }
}

.active-menu {
    .menu-overlay {
        pointer-events: all;
        opacity: 1;
    }
}

.full-height-slider {
    min-height: calc(100vh - 80px);
}

.slideshow {
    position: relative;
    width: 100%;
    margin: 0;
    overflow: hidden;
    color: var(--text-color-white);
    box-shadow: 0 10px 25px 0 var(--dark-shadow);
    z-index: 1;

    @media screen and (max-width: 64em) {
        box-shadow: none;
    }

    .slide {
        width: 100%;
        height: 50vh;
        top: 0;
        left: 0;
        position: absolute;
        overflow-x: hidden;
        z-index: 1;
        opacity: 0;
    }

    .slide--current {
        opacity: 1;
        z-index: 3;
        position: relative;
    }

    .slide-wrap {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    .slide-img {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--bg-color);
        background-size: cover;
        background-position: center;
        position: absolute;
    }

    .slide-img {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-image: linear-gradient(90deg, rgba(26, 26, 26, 0.59) 0%, rgba(0, 0, 0, 0.00) 83%);
        }
    }

    .slide-title-wrap {
        padding: 2rem 1rem 0;
        text-align: center;
        position: absolute;

        @media screen and (min-width: 64em) {
            max-width: 36rem;
            right: calc(50% - 4rem);
        }

        @media screen and (max-width: 64em) {
            padding: 2rem 1rem;
            max-height: calc(100% - 120px);
        }
    }

    .slide-title {
        margin: 0.5rem 0 0;
        font-size: 2.6vw;
        line-height: 1;
        letter-spacing: 0.02rem;

        @media screen and (min-width: 64em) {
            font-size: 2.2vw;
        }

        > a {
            color: inherit;

            &:hover, &:focus {
                color: inherit;
                text-decoration: none;
            }
        }

        span {
            display: inline-block;
        }
    }

    .slide-subtitle {
        stop-opacity : 100%;
        font-weight: 500;
        letter-spacing: 0.02rem;
    }

    .boxnav {
        position: absolute;
        bottom: auto;
        left: 0;
        display: flex;
        z-index: 1000;
        width: 100%;
        z-index: 999;
    }

    .progress-nav-item {
        display: flex;
        align-items: center;
        background: var(--color-nav-bg);
        border: 0;
        height: 4rem;
        flex: 1;

        @media screen and (max-width: 64em) {
            -webkit-box-pack: center;
            justify-content: center;
        }
    }

    .progress-nav-item--prev:hover,
    .progress-nav-item--next:hover {
        color: var(--color-nav-hover);
    }

    .progress-nav-item:focus {
        outline: none;
    }

    .progress-nav-label {
        padding: 0 0.5rem;
        font-size: 1.15rem;
        position: relative;
        font-family: $title-font;
        font-size: 1.4rem;
    }

    .progress-nav-label-dash-container {
        width: 175px;
        height: 3px;
        background-color: var(--text-color-white);

        .progress-nav-label-dash-current {
            height: 100%;
            background-color: var(--orange-color);
        }
    }

    .slide {
        height: 100%;
    }

    .slide-title-wrap {
        max-width: 32rem;
        text-align: left;
        width: 50%;
        right: 50%;
        left: auto;
        z-index: 999;

        @media screen and (max-width: 64em) {
            width: calc(100% - 2rem);
            right: 1rem;
            left: 1rem;
            padding-bottom: 64px;
        }
    }

    .slide-title {
        font-size: 2.6vw;

        @media screen and (max-width: 64em) {
            font-size: 2.4rem;
        }

        @media screen and (max-width: 48em) {
            font-size: 1.8rem;
        }
    }

    .slide-subtitle {
        margin: 1rem 0 0 0.2rem;

        @media screen and (max-width: 64em) {
            font-size: 13px;
        }

    }

    .slide-category,
    .slide-anchor {
        text-transform: uppercase;
        letter-spacing: 0.02rem;
        font-weight: 700;
        font-size: 13px;


        @media screen and (max-width: 64em) {
            font-size: 12px;
        }
    }

    .slide-anchor {
        color: var(--text-color-white);
        margin: 1rem 0 0 0.2rem;
        display: inline-block;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -5px;
            height: 2px;
            background-color: var(--text-color-white);
            transform: scale3d(0, 1, 1);
            transform-origin: 0 50%;
            transition: transform 0.1s;
            max-width: 95%;
        }

        &:hover {
            &:after {
                transform: scale3d(1, 1, 1);
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 0.3s;
            }
        }
    }

    .boxnav {
        left: auto;
        width: 36rem;
        right: calc(50% - 4rem);

        @media screen and (max-width: 64em) {
            left: 0;
            right: 0;
            text-align: center;
            width: 100vw;
            bottom: 46px;
        }
        

        @media screen and (max-height: 520px) {
            bottom: 0;
        }
    }

    &:after {
        @media screen and (max-width: 64em) {
            content: '';
            position: absolute;
            bottom: 0;
            width: 3px;
            height: 36px;
            background-color: var(--bg-color);
            z-index: 999;
            left: calc(50% - 1px);
        }

        @media screen and (max-height: 520px) {
            display: none;
        }
    }
}

.nav-items {
    z-index: 2;
    width: 100%;
    min-height: 130px;

    .nav-item {
        padding: 0.75rem 1rem;
        margin-top: 0.5rem;
        cursor: pointer;

        * {
            cursor: pointer;
        }

        @media screen and (min-width: 108em) {
            padding: 2rem 3rem;
        }

        &.border-right {
            border: 0;

            &:after {
                content: '';
                position: absolute;
                right: 0.5rem;
                height: 70%;
                top: 15%;
                bottom: 15%;
                width: 2px;
                background-color: var(--bg-color-subtle);
            }
        }

        .nav-item-index {
            padding-right: 1.5rem;
            font-family: $title-font;
            color: var(--text-color-subtle);
            font-size: 2.5rem;
            line-height: 28px;


            @media screen and (min-width: 86em) {
                padding-right: 2rem;
            }

            bdi {
                display: block;
                margin: 0;
                width: 100%;
                text-align: center;
                font-size: 16px;
                margin-top: 0.25rem;
                text-transform: uppercase
            }
        }

        .nav-item-info {
            min-height: 90px;

            p {
                margin: 0;
                line-height: 1.2;

                &.title {
                    font-weight: 500;
                    font-size: 16px;
                    margin: 0.25rem 0;
                }

                &.description {
                    font-size: 14px;
                }

                &.category {
                    font-size: 11px;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: var(--text-color-subtle);
                }
            }
        }

        &.current,
        &:hover {
            .nav-item-index {
                color: var(--anchor-hover-color);
            }
        }

        p {
            @media screen and (max-width: 64em) {
                font-size: 14px;
            }
        }
    }

    .current-selector {
        position: absolute;
        top: 0;
        height: 5px;
        width: 25%;
        left: 0;
        z-index: 3;
        margin: 0;

        &:before {
            content: '';
            position: absolute;
            width: calc(100% - 2rem);
            left: 1rem;
            top: 0;
            bottom: 0;
            background-color: var(--orange-color);

            @media screen and (min-width: 108em) {
                left: 0.5rem;
            }
        }

        &.video {
            &:before {
                @media screen and (min-width: 108em) {
                    width: calc(100% - 3rem);
                }
            }
        }
    }
}


@media screen and (min-width: 108em) {
    .max-width-4 .nav-items .nav-item {
        padding: 0.75rem;
    }
}

.video-preview {
    width: 420px;
    min-width: 320px;
    margin-top: -42px;

    @media screen and (max-width: 76em) {
        bottom: 100%;
        position: absolute;
        right: 0;
        width: 320px;
    }

    @media screen and (max-width: 52em) {
        position: relative;
        top: auto;
        bottom: auto;
        margin-top: 0;
        width: 100%;
    }
}


.absolute {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;

    .icon-play {
        position: absolute;
        width: 66px;
        height: 66px;
        line-height: 66px;
        border-radius: 50%;
        left: calc(50% - 33px);
        top: calc(50% - 33px);
        z-index: 3;
        color: rgba(255, 255, 255, 0.85);
        background-color: rgba(0, 0, 0, 0.3);
        text-align: center;
        font-size: 21px;
        overflow: hidden;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s;

        &:before {
            position: absolute;
            left: calc(50% - 8px);
            top: calc(50% - 8px);
            line-height: 16px;
        }
    }
}


main {
    overflow: hidden;
    
    .container {
        display: flex;
        margin: 0 auto;
        width: 64rem;
        max-width: 90vw;
        justify-content: space-between;
        align-items: flex-start;
        padding: 2rem 0;

        &.pt0 {
            padding-top: 0;
        }

        &.pb0 {
            padding-bottom: 0;
        }

        &.py0 {
            padding-top: 0;
            padding-bottom: 0;
        }

        &.left {
            float: none;
            justify-content: left;
        }

        @media screen and (max-width: 64em) {
            display: block;

            &.full-mobile {
                max-width: 100vw;
                padding-top: 0;

                .mobile-container {
                    max-width: 90vw;
                }
            }
        }
    }

    .filter-list-page {
        .container.custom-filters {
            @media screen and (max-width: 64em) {
                padding: 1rem;
                max-width: 100vw;
                line-height: 1;
                background-color: var(--bg-color);
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                height: 80px;
                opacity: 0;
                pointer-events: none;
                opacity: 0;
                transform: translateY(-160px);
                will-change: opacity, transform;
                transition: all 0.3s linear;
                box-shadow: 0 10px 25px 0 var(--dark-shadow);

                &.fixed {
                    pointer-events: all;
                    transform: translateY(0);
                    z-index: 99;
                    opacity: 1;
                }

                .month-filter {
                    float: right;

                    > button {
                        height: calc(80px - 2rem);
                        background-color: var(--bg-color);
                        width: 100%;
                        width: 100%;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        position: absolute;

                        i {
                            margin-left: 0.5rem;
                            font-size: 24px;
                            vertical-align: middle;
                        }
                    }
                    
                    > input[type="month"] {
                        position: relative;
                        border: 0;
                        outline: 0;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        text-indent: -999px;
                        z-index: 2;
                        opacity: 0;
                        height: calc(80px - 2rem);
                        width: 100%;

                        &::-webkit-calendar-picker-indicator {
                            background: transparent;
                            bottom: 0;
                            color: transparent;
                            cursor: pointer;
                            height: auto;
                            left: 0;
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: auto;
                        }
                    }
                }

                .type-filter {
                    > button {
                        height: calc(80px - 2rem);
                        background-color: var(--bg-color);
                        width: 100%;
                        width: 100%;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        position: absolute;

                        i {
                            margin-left: 0.5rem;
                            font-size: 24px;
                            vertical-align: middle;
                        }
                    }

                    > select {
                        position: relative;
                        border: 0;
                        outline: 0;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        text-indent: -999px;
                        z-index: 2;
                        opacity: 0;
                        height: calc(80px - 2rem);
                        width: 100%;
                    }
                }
            }
        }

        .section-title {
            font-size: 2.4rem;
        }
    }
}

.articles-list {
    padding-top: 0;
    padding-bottom: 0;

    &.container {
        min-height: 568px;

        @media screen and (max-width: 64em) {
            display: block;
        }
    }
}

.box {
    background-color: var(--bg-color);
    border-radius: 4px;
    margin: 1rem;
    padding: 1.5rem 1rem;

    &.full {
        padding: 0.5rem 1rem;
    }

    &.bg-dark {
        background-color: var(--bg-color-dark);
    }

    &:hover {
        box-shadow: 0 10px 25px 0 var(--dark-shadow);
    }

    form {
        margin: 1rem 0 0;
    }

    &.box-content {
        margin: 0 auto;
        padding: 2rem;

        @media screen and (max-width: 64em) {
            padding: 1rem;
        }
    }

    &.highlighted-points {
        padding-right: 0;

        .title-container {
            padding-right: 1rem;
        }
    }
}

.section-title {
    margin: 0;
    display: block;
    font-size: 1.6rem;

    @media screen and (max-width: 64em) {
        font-size: 1.2rem;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.section-title-separator {
    border-bottom-color: var(--text-color);
    border-width: 4px;
    margin: 0.5rem 0;

    @media screen and (max-width: 64em) {
        border-width: 3px;
    }
}

.section-text {
    font-size: 90%;
    line-height: 1.4;
    padding-left: 5px;

    &.small {
        font-size: 80%;
        line-height: 1.2;
    }

    &.big {
        font-size: 120%;
        line-height: 1.6;
        padding-left: 0;
    }
}

.form-control {
    padding-bottom: 0.75rem;

    &.floating-submit {
        input {
            padding-right: 25px;
            height: 24px;
            background-color: transparent;

            &.error {
                border-bottom-color: var(--error-color);
            }

            &.success {
                border-bottom-color: var(--success-color);
            }

            &.ng-not-empty~.error {
                display: none;
            }

            &.ng-not-empty~button[type="submit"] {
                opacity: 1;
            }

            &.ng-empty~button[type="submit"],
            &.ng-invalid~button[type="submit"] {
                opacity: 0;
            }
        }

        button[type="submit"] {
            position: absolute;
            right: 0;
            top: 0;
            line-height: 24px;
            opacity: 0;
            transition: opacity 0.3s;

            i {
                font-size: 18px;
            }
        }
    }

    .feedback {
        font-size: 11px;
        line-height: 1.2;
        position: absolute;
        bottom: -5px;
        left: 5px;
        visibility: hidden;
    }

    .error+.feedback {
        visibility: visible;
        color: var(--error-color);
    }

    .success+.feedback {
        font-size: 13px;
        visibility: visible;
        color: var(--success-color);
    }
}

.column {
    >.box {
        margin-left: 0;
        padding-bottom: 0.25rem;

        @media screen and (max-width: 52em) {
            margin: 0 auto;
        }
    }
}

.social-list {
    .social-btn {
        font-size: 90%;
        border-radius: 4px;
        background-color: var(--bg-color-subtle);
        padding: 0.25rem 0.5rem;

        >a {
            display: flex;
            align-items: center;
            justify-content: start;
        }

        img {
            height: 16px;
            margin-right: 0.5rem;
        }
    }
}

.card {
    background-color: var(--bg-color);
    margin: 0.5rem 0;
    padding-right: 1rem;
    overflow: hidden;

    &.full {
        &:first-child {
            margin-top: 0;
        }

        margin: 0.75rem 0;
    }

    a,
    a:hover {
        text-decoration: none;
    }

    .image-column {
        width: 110px;
        padding-right: 15px;
        flex: none;

        @media screen and (max-width: 64em) {
            width: 90px;
            padding-right: 10px;
        }
    }

    h1 {
        line-height: 1.2;
        margin: 0 0 5px;
        font-family: $text-font;
        font-size: 95%;
    }

    &.full {
        h1 {
            font-family: $title-font;
            font-size: 130%;

            @media screen and (max-width: 64em) {
                line-height: 1;
            }
        }

        .image-column {
            width: 200px;

            @media screen and (max-width: 64em) {
                width: 120px;
            }
        }

        .content-column {
            min-height: 185px;
            width: 70%;

            @media screen and (max-width: 64em) {
                min-height: 110px;
            }

            p {
                &.description {
                    margin: 0.5rem 0;
                }
            }
        }
    }

    .content-column {
        min-height: 95px;
        padding: 0.25rem 0;
        width: 100%;

        @media screen and (max-width: 64em) {
            min-height: 80px;
            justify-content: space-between;
        }

        >* {
            width: 100%;
            display: block;
        }

        p {
            line-height: 1.2;
            margin: 0 0 5px;

            &.category {
                font-weight: 700;
                font-size: 70%;
                text-transform: uppercase;
                letter-spacing: 0.02rem;
                color: var(--text-color-subtle);

                @media screen and (max-width: 64em) {
                    letter-spacing: 0.03rem;
                    font-weight: 500;
                    color: var(--dark);
                }
            }

            &.description {
                color: var(--text-color-subtle);
                margin-bottom: 0.5rem;
            }

            &.info {
                margin-top: 5px;
                font-weight: 500;
                font-size: 70%;
                line-height: 1.2;
            }
        }
    }

    .social-share-list {
        margin: 0;

        @media screen and (max-width: 64em) {
            display: none;
        }

        li,
        i {
            cursor: pointer;
        }

        .icon-anchor {
            font-size: 18px;

            &:before {
                transform: rotate(-45deg);
                display: inline-block;
            }

            &:hover {
                color: var(--anchor-hover-color);
            }
        }
    }

    .section-title-separator {
        border-width: 2px;
        border-bottom-color: var(--border-color-subtle);
    }

    .key-value-table {
        display: block;
        tbody{
            display: block;
        }
        tr {
            @media screen and (min-width: 65em) {
                display: flex;
                padding-bottom: 0;
            }

            td.table-row-title {
                color: var(--text-color);
                font-weight: 500;
                margin-right: 5px;
            }
        }
    }
}

.events-carousel-wrapper {
    width: calc(100% - 1rem);
    overflow: hidden;

    @media screen and (max-width: 64em) {
        width: 100%;
    }
}

.carousel-wrapper {
    display: flex;
    box-sizing: content-box;
    z-index: 1;
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0 0 0.75rem;
    padding: 0;

    &.disabled {
        a {
            pointer-events: none;
        }
    }
}


.carousel-navigation {
    @media screen and (max-width: 64em) {
        display: none;
    }
}

.navigation-dot {
    margin-left: 12px;
    border: 0;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: var(--title-color-subtle);
    line-height: 12px;
    cursor: pointer;
    padding: 0;

    &:before {
        content: '';
        position: absolute;
        left: -10px;
        right: -10px;
        top: -20px;
        bottom: -20px;
    }
    
    &.current {
        background-color: var(--dark);
    }

    &:focus {
        outline-style: dotted;
        outline-width: 0.05em;
        outline-color: rgba(0, 0, 0, 0.3);
    }

    &:first-child {
        margin-left: 0;
    }
}

.navigation-arrow {
    margin-left: 15px;
    margin-right: 15px;
    border: 0;
    width: 24px;
    height: 12px;
    background-color: transparent;
    color: var(--text-color-white);
    line-height: 12px;
    cursor: pointer;
    padding: 0;

    &:before {
        content: '';
        position: absolute;
        left: -10px;
        right: -10px;
        top: -20px;
        bottom: -20px;
    }
  
    i {
        font-size: 21px;
        line-height: 12px;
        color: var(--text-color-white);
    }

    &:first-child {
        margin-right: 3px;
    }

    &:focus {
        outline-style: dotted;
        outline-width: 0.05em;
        outline-color: rgba(0, 0, 0, 0.4);
    }
}


.image-overlay {
    line-height: 0;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(26, 26, 26, 0.39);
        transition: opacity 0.3s ease-out;
        opacity: 0;
    }
}

.vertical-card {
    width: calc(33.3333% - 0.5rem);
    min-width: 205px;
    margin-right: 0.75rem;
    flex-shrink: 0;

    a {
        height: 100%;
        background-color: var(--title-color-subtle);
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }

    &:first-child a {
        background-color: var(--dark);
    }

    &.draggable {
        cursor: grab;

        a {
            cursor: inherit;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    img {
        pointer-events: none;
    }


    .card-content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1.5rem 1rem;
        transition: opacity 0.6s ease-out, transform 0.4s;
        transform: translate3d(0, 70%, 0);
        opacity: 0;

        h1 {
            color: var(--text-color-white);
            font-size: 18px;
            margin: 0 0 0.25rem;
            line-height: 1;
        }

        p {
            color: var(--text-color-white);
            font-size: 14px;
            margin: 0;
            line-height: 1;
        }
    }

    &:hover,
    &.current {
        .image-overlay:after {
            opacity: 1;
        }

        .card-content {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
}

footer {
    .vertical-separator {
        background-color: var(--title-color-subtle);
        width: 2px;
        margin: 0 10px;
        display: block;
        height: 100%;
        margin: 0 auto;
    }

    .copyright {
        text-transform: uppercase;

        .section-text {
            font-size: 13px;
            letter-spacing: 0.01rem;

            @media screen and (max-width: 64em) {
                text-align: center;
                max-width: 200px;
                margin: 0 auto;
            }
        }
    }

    @media screen and (max-width: 64em) {
        padding: 0 1rem;

        >div {
            margin: 0.5rem auto;
        }
    }
}

.key-value-table {
    tr {
        display: block;
        padding-bottom: 0.5rem;

        td {
            display: block;
            padding: 0.1rem 0;

            &.table-row-title {
                font-weight: 500;
                font-size: 13px;
                color: var(--text-color-subtle);
                text-transform: uppercase;
            }

            &.section-text {
                font-size: 15px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 90%;
            }
        }
    }
}

.app-info {
    min-height: 420px;
    border-radius: 4px;
    margin: 0 1rem 1rem 0;
    padding: 1.5rem;
    color: var(--text-color-white);
    overflow: hidden;

    @media screen and (max-width: 52em) {
        margin: 0 0 1rem;
    }

    >* {
        width: 50%;
    }

    hr {
        background-color: var(--bg-color);
        border-bottom-width: 0;
        margin: 0.5rem 0;
        height: 4px;
        width: 0;
        transition-delay: 0.8s;
        transition: width 0.2s ease-out;
        transform-origin: 0 50%;
    }

    .section-title {
        font-size: 1.8rem;
        line-height: 1.1;
        max-width: 50%;
        opacity: 0;
        transform: translate3d(0, 50%, 0);
        transition-delay: 0.8s;
        transition: opacity 1s ease-out, transform 0.4s;

        @media screen and (max-width: 64em) {
            font-size: 1.6rem;
        }
    }

    .section-text {
        font-size: 16px;
        max-width: 50%;
        opacity: 0;
        transition-delay: 1.8s;
        transition: opacity 1.2s ease-out;

        a {
            color: inherit;
            font-weight: 600;
        }
    }

    .image {
        position: absolute;
        right: 0;
        top: 10%;
        opacity: 0;
        transform: translate3d(0, 50%, 0);
        transition-delay: 1.8s;
        transition: opacity 0.4s, transform 1s;
    }

    &.on-screen {
        hr {
            width: 100%;
        }

        .section-title,
        .image {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        .section-text {
            opacity: 1;
        }
    }
}

.points-tree {
    .section-title {
        margin: 1rem 0;
    }
}

.page-title {
    font-size: 3.4rem;
    line-height: 1;
    border-bottom: 5px solid var(--text-color);
    padding-bottom: 1rem;
    margin: 0.5rem 0;

    @media screen and (max-width: 64em) {
        font-size: 1.8rem;
        line-height: 1.2;
        border-bottom-width: 3px;
    }
}

.description {
    @media screen and (max-width: 64em) {
        font-size: 15px;
    }
}

.square-card {
    display: block;

    .card-content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1.5rem 1rem;
        transition: opacity 0.6s ease-out, transform 0.4s;
        transform: translate3d(0, 70%, 0);
        opacity: 0;

        h1 {
            color: var(--text-color-white);
            font-size: 18px;
            margin: 0 0 0.5rem;
            line-height: 1;
        }

        p {
            color: var(--text-color-white);
            font-size: 14px;
            margin: 0;
            line-height: 1;
        }

        .section-separator {
            background-color: var(--bg-color);
            border-bottom-width: 0;
            margin: 0.25rem 0;
            height: 3px;
            width: 0;
            transition: width 0.2s ease-out;
            transition-delay: 0.2s;
            transform-origin: 0 50%;
        }

        .fake-anchor {
            font-size: 11px;
            text-transform: uppercase;
            color: var(--text-color-white);
            font-weight: 700;
        }
    }

    &:hover {
        .image-overlay:after {
            opacity: 1;
        }

        .card-content {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        .section-separator {
            width: 100%;
        }
    }
}

.video-banner {
    overflow: hidden;

    &.fit {
        video {
            min-width: 100vw !important;
            min-height: 100%;
            transform: translate(-50%, -50%);
            position: absolute;
            left: 50%;
            top: 50%;
        }
    }

    video {
        object-fit: cover;
    }

    .controls-overlay {
        cursor: pointer;
        left: 0;
        top: 0;

        &.playing {
            z-index: -1;
            
            i {
                opacity: 0;
            }
        }
    }
}

.banner {
    width: 100vw;
    overflow: hidden;
}

.gallery-container {
    background-color: var(--bg-color-dark);
    display: flex;
    box-sizing: content-box;
    z-index: 1;
    width: 100%;
    height: 100%;
    list-style: none;
    padding: 0;

    @media screen and (max-width: 64em) {
        margin-bottom: 60px;
    }

    .psv-loader-container .psv-loader {
        display: none !important;
    }

    .gallery-item {
        width: 100%;
        -ms-flex-negative: 0;
        flex-shrink: 0;

        .slide-img {
            background-size: cover;
            background-position: center;
        }
       
        .icon-panorama {
            position: absolute;
            color: var(--text-color-white);
            font-size: 24px;
            left: 5%;
            top: calc(100% - 45px);
            width: 25px;
            height: 25px;
        }
    }
}

.gallery-navigation {
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;

    @media screen and (max-width: 64em) {
        bottom: 25px;
    }

    .navigation-dot {
        background-color: transparent;
        border: 1px solid var(--bg-color);

        @media screen and (max-width: 64em) {
            border-color: var(--text-color);
        }
 
        &.current {
            background-color: var(--bg-color);

            @media screen and (max-width: 64em) {
                background-color: var(--bg-color-dark);
            }
        }

        label {
            color: var(--text-color-white);
            text-align: center;
            position: absolute;
            right: calc(50% - 10px);
            bottom: -15px;
            font-weight: 700;
            font-size: 10px;
        }
    }

    button:first-child {
        margin-left: 0;
    }

    @media screen and (max-width: 64em) {
        .navigation-arrow {
            width: 75px;
            height: 75px;
            position: absolute;
            top: calc(50% - 75px);
            line-height: 75px;
            z-index: 3;
            padding: 0 10px;
            margin: 0;
            outline: none;

            i  {
                color: var(--text-color);
            }
        }
    }
}

.single {
    header {
        padding: 1.5rem 4rem 1.5rem 0;

        @media screen and (max-width: 64em) {
            padding-right: 2rem;
        }

        @media screen and (max-width: 52em) {
            padding-right: 0;
        }

        .section-title {
            font-size: 3rem;
            line-height: 1;

            @media screen and (max-width: 64em) {
                font-size: 2.1rem;
            }
        }

        .share-section ul {
            margin: 0 0 1rem;
        }

        .date,
        .category {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .main-content {
        padding-top: 1rem;

        >div {
            @media screen and (max-width: 64em) {
                padding-right: 2rem;
            }

            @media screen and (max-width: 52em) {
                padding-right: 0;
                padding-bottom: 1rem;
            }
        }
    }
}

.single-point {
    .main-content {
        .container {
            @media screen and (max-width: 64em) {
                padding-top: 0;
            }
        }
    }
}

.nucleus {
    .banner {
        .navigation-arrow {
            @media screen and (max-width: 64em) {
                width: 75px;
                height: 75px;
                position: absolute;
                top: calc(50% - 75px);
                z-index: 3;
                padding: 0 10px;
                margin: 0;
                outline: none;

                i {
                    font-size: 32px;
                    vertical-align: middle;
                }

                &.navigate-prev {
                    text-align: left;
                }

                &.navigate-next {
                    right: 0;
                    text-align: right;
                }
            }
        }
    }

    .main-content {
        .container {
            @media screen and (max-width: 64em) {
                padding-top: 0;
            }
        }
    }

    .bg-subtle {
        @media screen and (max-width: 64em) {
            background-color: var(--bg-color);
        }

        &.sub-container {
            background-color: var(--bg-color);
        }
    }
}

.share-section {
    .share-btn {
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            left: -10px;
            right: -10px;
            top: -20px;
            bottom: -20px;
        }
    }

    label {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 700;
        margin-right: 0.75rem;
        color: var(--text-color-subtle);
    }

    ul {
        li {
            &:not(:first-child) {
                margin-left: 0.4rem;
            }
        }
    }
}

.content {
    aside {
        .key-value-table {
            tr {
                padding: 0.75rem 0;

                &:not(:last-child) {
                    border-bottom: 1px solid var(--border-color-subtle);
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
}

aside {
    .box {
        .card {
            .image-column {
                width: 90px;
                padding-right: 10px;
            }

            &:not(.last) {
                margin-bottom: 1rem;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -0.25rem;
                    height: 1px;
                    background-color: var(--dark-shadow);
                }
            }
        }
    }
}

.vertical-filters {
    list-style: none;
    padding-left: 0;

    li {
        margin-bottom: 0.5rem;

        a {
            font-family: $title-font;
            text-transform: capitalize;
            font-size: 120%;
            color: var(--text-color);

            &:hover {
                color: var(--anchor-hover-color);
            }

            &.disabled {
                cursor: default;
                color: var(--text-color-subtle);
                pointer-events: none;

                &:hover {
                    color: var(--text-color-subtle);
                }
            }
        }

        &.current {
            a {
                color: var(--anchor-hover-color);
            }
        }

        &.permanent {
            margin-bottom: 2rem;

            a:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -1rem;
                height: 1px;
                background-color: var(--dark-shadow);
            }
        }
    }
}

.image-label {
    p {
        margin: 1rem 0 0;
        line-height: 1.2;
    }
}

.years-carousel-container {
    width: 145px;
    overflow: hidden;
}

.years-carousel {
    padding-left: 0;
    width: auto;
    display: flex;
    box-sizing: content-box;
    z-index: 1;
    width: 100%;
    list-style: none;

    li {
        display: inline-block;
        text-align: center;
        margin: 0 2px;
        min-width: 45px;
        color: var(--text-color-subtle);

        &.current {
            color: var(--dark);
            font-weight: 700;
        }

        a {
            color: inherit;
            font-weight: inherit;
            cursor: pointer;

            &:hover {
                color: var(--dark);
                font-weight: 700;
            }
        }
    }
}

.nucleus {

    .slide-container {
        box-sizing: content-box;
        z-index: 1;
        width: 100%;
        height: 100%;
        list-style: none;
        padding: 0;

        @media screen and (max-width: 64em) {
            flex-wrap: wrap;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
        }
    }

    .nucleus-column {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        overflow: hidden;
        min-height: 650px;
        width: 33.33%;
        will-change: width, transform;
        transition: all 0.2s linear;
        overflow: hidden;
        backface-visibility: hidden;
        transform: translateY(0);

        @media screen and (max-width: 64em) {
            min-height: 186px;
            max-width: 100%;
            width: 100%;
        }

        .background {
            z-index: -1;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.2s linear;
            transform: scale(1);
            will-change: transform, filter;
        }

        &:hover {
            .background {
                transform: scale(1.1);
                filter: blur(2px);
                -webkit-filter: blur(2px);
            }
        }

        >.content {
            color: var(--text-color-white);
            padding: 2rem 10%;
            width: 100%;

            @media screen and (max-width: 64em) {
                -webkit-box-pack: center;
                justify-content: center;
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.4);
            }
        }

        .title {
            font-size: 3.2rem;
            max-width: 375px;
            margin: 0;

            @media screen and (max-width: 64em) {
                font-size: 1.8rem;
                line-height: 1.2;
                max-width: 210px;
                margin: 0 auto;
                text-align: center;
            }
        }

        .section-title-separator {
            border-color: var(--white);

            @media screen and (max-width: 64em) {
                display: none;
            }
        }

        .excerpt {
            font-size: 18px;
            line-height: 21px;
            min-height: 90px;
            margin-bottom: 2rem;
        }

        .icon {
            @media screen and (max-width: 64em) {
                display: none;
            }
        }
    }
}

.nucleus-submenu {
    background-color: transparent;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 64em) {
        padding-right: 0;
        padding-left: 2rem;
        margin: 0;
        width: 100vw;
        margin-left: -2.5rem;
        font-size: 14px;

        > ul {
            justify-content: flex-start;
            overflow-x: scroll;
            white-space: nowrap;
            padding: 0;

            li {
                display: inline-block;
                vertical-align: top;
                margin: 0 0.5rem;
            }
        }
    }

    li {
        a {
            &.active {
                &:after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    right: 0;
                    height: 3px;
                    background-color: var(--anchor-hover-color);
                }
            }
        }
    }
}

.contacts-map {
    min-height: 450px;
    width: 100%;
}

.map-element {
    height: 100%;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


.absolute-filters {
    @media screen and (min-width: 64em) {
        display: none;
    }

    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--dark);
    padding: 0.75rem 2rem;
    min-height: 50px;
    z-index: 99;

    select {
        position: absolute;
        left: 0;
        width: 100vw;
        top: 0;
        bottom: 0;
        text-indent: -999px;
        overflow: hidden;
        z-index: 2;
    }

    #currentCategory {
        font-size: 21px;
        font-family: $title-font;
        color: var(--white);
    }

    &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 50px;
        background-color: var(--anchor-hover-color);
    }

    &:after {
        content: $icon-triangle-down;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        line-height: 50px;
        width: 50px;
        font-size: 32px;
        text-align: center;
        background-color: var(--anchor-hover-color);
        font-family: '#{$icomoon-font-family}' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        color: var(--white);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.watermark {
    position: fixed;
    left: 0;
    right: 0;
    top: 60px;
    font-family: $title-font;
    color: var(--text-color);
    font-size: 23vw;
    line-height: 1;
    text-align: center;
    opacity: 0.1;

    @media screen and (max-width: 64em) {
        top: 80px;
    }
}

.search {
    .search-form {
        padding-bottom: 0;

        input {
            border: 0;
            box-shadow: none;
            width: 100%;
            padding: 1.25rem 0 2.75rem;
            font-size: 18px;
            outline: none;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            height: 3px;
            right: 0;
            top: 100%;
            background-color: var(--bg-color-dark);
        }

        >button.form-control-submit {
            line-height: 21px;
            display: inline-block;
            top: calc(50% - 21px);

            i {
                font-size: 21px;
            }
        }

        .feedback {
            font-size: 14px;
            bottom: 5px;
            left: 0;
        }
    }

    .container {
        margin-top: 0;
        padding-bottom: 0;

        &.grid {
            min-height: 450px;
            padding-top: 0.5rem;

            @media screen and (max-width: 64em) {
                min-height: 200px;
            }
        }

        .box {
            padding: 1.5rem;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }

    ul.filters {
        margin-top: 1.5rem;

        @media screen and (max-width: 64em) {
            display: block;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            overflow-x: scroll;
            white-space: nowrap;
            padding-right: 0;
            padding-left: 1rem;
            margin: 0;
            width: 100vw;
            margin-left: -1.5rem;

            li {
                margin: 10px 7px;
                display: inline-block;
            }
        }

        a {
            &.active {
                color: var(--anchor-hover-color);
                font-weight: 500;
            }

            span {
                font-weight: 500;
            }
        }
    }

    .card {
        &.full {
            padding: 0 1rem;

            .image-column {
                width: 100px;
            }

            .content-column {
                -webkit-box-pack: justify;
                justify-content: space-between;
            }

            h1 {
                font-family: $text-font;
                font-size: 95%;
                font-weight: 500;
                line-height: 1.2;
                margin-bottom: 0;
            }

            p {
                margin: 0 0 5px;
            }

            .content-column {
                min-height: 95px;
            }
        }
    }

    .no-results {
        padding: 0;
    }

    .pagination {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            margin: 0 5px;

            a {
                font-weight: 500;
            }

            &.active,
            &:hover:not(.disabled) {
                a {
                    color: var(--anchor-hover-color);
                }
            }

            &.disabled {
                a {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }

            &:first-child,
            &:last-child {
                margin: 0 8px;

                a {
                    font-size: 26px;
                }
            }
        }
    }
}

.outdoor-map {
    .map-navigation {
        overflow: hidden;

        .container {
            @media screen and (max-width: 64em) {
                padding: 0;
                width: 100vw;
                max-width: none;
                margin: 0;
            }
        }

        ul {
            @media screen and (max-width: 64em) {
                display: flex;
                box-sizing: content-box;
                z-index: 1;
                overflow: auto;
                white-space: nowrap;
                min-width: 100%;
                height: 100%;
                list-style: none;
                margin: 0;
            }

            li {
                @media screen and (max-width: 64em) {
                    display: inline-block;
                    padding: 2rem 0;
                }

                a {
                    color: var(--text-color-white);
                    font-family: $title-font;
                    font-size: 140%;
                    margin: 0 1rem;
                    opacity: 0.6;

                    &:hover, &.active {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.events {

}